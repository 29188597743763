import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useParams, withRouter, generatePath, useLocation } from 'react-router-dom'
import moment from 'moment-timezone'
import { message, Table, Drawer, Spin, Row, Col } from 'antd'
// import { CheckOutlined, StopOutlined } from '@ant-design/icons'

import Partner from './Partner'
import { useStores } from '../../../Store/Store'
import {
  ADMIN_PARTNERS_PAGE,
  ADMIN_PARTNER_PAGE,
  ADMIN_PARTNERS_CONTENT_PAGE,
  ADMIN_PARTNER_CONTENT_PAGE,
  ADMIN_PARTNERS_RESEARCHER_PAGE,
  ADMIN_PARTNER_RESEARCHER_PAGE
} from '../../../routes'
import AdminDetailsExport from '../../../components/Admin/AdminExport/AdminDetailsExport'
import getColumnSearchProps from '../../../components/SearchFilter/SearchFilter'

import './Partner.css'
import {
  usePartnerStatusesQuery,
  // usePartnerYoudoStatusesQuery,
  // usePartnerSecurityStatusesQuery,
  // usePartnerVerificationStatusesQuery,
  // usePartnerFormStatusesQuery,
  // usePartnerLmkFormStatusesQuery,
  // usePartnerCardStatusesQuery,
  usePartnerSmzStatusesQuery
  // usePartnerFireBriefingFormStatusesQuery,
  // usePartnerEmploymentTypeFormStatusesQuery,
  // usePartnerRequisitesStatusesQuery
} from '../../../api/react-query/dict'
import {
  useQueryAdminPartnersContentList,
  useQueryAdminPartnersList,
  useQueryAdminPartnersResearcherList
} from '../../../api/react-query/adminPartners'
import {
  oneMinute,
  paginationPageSizeList,
  partnerListColKeys,
  rolePartnerListColsKeys,
  twoMinutes
} from '../../../Constants'
// import AutoCompleteFilter from '../../../components/AutoCompleteFilter/AutoCompleteFilter'
// import { useCities, usePartnerRoles } from '../../../hooks'
import TableSettings from '../../../components/Common/TableSettings/TableSettings'
import { AppConfig } from '../../../AppConfig'
import PortalButton from '../../../components/Admin/PortalButton/PortalButton'
import AssignPartnerButton from '../../../components/Admin/AssignPartnerButton/AssignPartnerButton'
import AssignNewPartnerButton from '../../../components/Admin/AssignNewPartnerButton/AssignNewPartnerButton'
import { ConfigContext } from '../../../context/configContext'
import UserCitiesButton from '../../../components/Admin/UserCities/UserCitiesButton'
import { useMutateLog } from '../../../api/react-query/log'
import UnbanByPhone from '../../../components/Admin/UnbanByPhone/UnbanByPhone'
import PartnerTakeNewPriorityButton
  from '../../../components/Admin/PartnerTakeNewPriorityButton/PartnerTakeNewPriorityButton'
import CheckUUIDPhone from '../../../components/Admin/CheckUUIDPhone/CheckUUIDPhone'

const tableScroll = {
  x: 'max-content'
}

function Partners ({ history }) {
  const { setConfig, user, isExternalCoordinator, isCoordinator, isRecruiter, portalButtonState } = useContext(ConfigContext)
  const store = useStores()
  const [isLoading, setIsLoading] = useState(true)
  const [fakeEmptyData, setFakeEmptyData] = useState(null)
  const { id } = useParams()
  const location = useLocation()
  const currentRoute = useMemo(() => {
    if ([ADMIN_PARTNERS_PAGE, `${ADMIN_PARTNERS_PAGE}/${id}`].includes(location.pathname)) {
      return {
        listRoute: ADMIN_PARTNERS_PAGE,
        idRoute: ADMIN_PARTNER_PAGE
      }
    }
    if ([ADMIN_PARTNERS_CONTENT_PAGE, `${ADMIN_PARTNERS_CONTENT_PAGE}/${id}`].includes(location.pathname)) {
      return {
        listRoute: ADMIN_PARTNERS_CONTENT_PAGE,
        idRoute: ADMIN_PARTNER_CONTENT_PAGE
      }
    }
    if ([ADMIN_PARTNERS_RESEARCHER_PAGE, `${ADMIN_PARTNERS_RESEARCHER_PAGE}/${id}`].includes(location.pathname)) {
      return {
        listRoute: ADMIN_PARTNERS_RESEARCHER_PAGE,
        idRoute: ADMIN_PARTNER_RESEARCHER_PAGE
      }
    }
  }, [id, location])

  const isContentPartners = useMemo(() => {
    return currentRoute?.listRoute === ADMIN_PARTNERS_CONTENT_PAGE
  }, [currentRoute?.listRoute])

  const isResearcherPartners = useMemo(() => {
    return currentRoute?.listRoute === ADMIN_PARTNERS_RESEARCHER_PAGE
  }, [currentRoute?.listRoute])

  const lsColumnsKey = useMemo(() => {
    return isContentPartners
      ? 'partnerContentColumns'
      : isResearcherPartners
        ? 'partnerResearcherColumns'
        : 'partnerColumns'
  }, [isContentPartners, isResearcherPartners])

  const lsPartnersRequest = useMemo(() => {
    return isContentPartners
      ? 'partnersContentRequest'
      : isResearcherPartners
        ? 'partnersResearcherRequest'
        : 'partnersRequest'
  }, [isContentPartners, isResearcherPartners])

  const pagination = {
    current: store.adminPartnerList.pageNumber,
    total: store.adminPartnerList.resultsTotal,
    pageSize: store.adminPartnerList.pageSize,
    pageSizeOptions: paginationPageSizeList
  }
  const partnersRequest = {
    pageNumber: pagination.current,
    pageSize: pagination.pageSize,
    ...(JSON.parse(localStorage.getItem(lsPartnersRequest) || '{"orderBy":"registrationDate desc"}'))
  }
  // в localStorage может быть сохранено старое значение pageSize = 0 которое ломает запрос
  partnersRequest.pageSize = partnersRequest.pageSize || pagination.pageSize
  const [request, setRequest] = useState(partnersRequest)
  // const { data: cities } = useCities()
  const [allowExport, setAllowExport] = useState(false)
  const [statuses, setStatuses] = useState([])
  // const [verificationStatuses, setVerificationStatuses] = useState([])
  // const [formStatuses, setFormStatuses] = useState([])
  // const [lmkFormStatuses, setLmkFormStatuses] = useState([])
  // const [employmentTypeFormStatuses, setEmploymentTypeFormStatuses] = useState([])
  // const [fireBriefingFormStatuses, setFireBriefingFormStatuses] = useState([])
  // const [securityStatuses, setSecurityStatuses] = useState([])
  // const [youdoStatuses, setYoudoStatuses] = useState([])
  const [smzStatuses, setSmzStatuses] = useState([])
  const [columnsKeys, setColumnsKeys] = useState(
    JSON.parse(localStorage.getItem(lsColumnsKey)) ||
    (rolePartnerListColsKeys[user?.groupName] || rolePartnerListColsKeys.defaultGroup)
  )

  // const { data: roles } = usePartnerRoles()

  const listMethod = useMemo(() => {
    return isContentPartners
      ? useQueryAdminPartnersContentList
      : isResearcherPartners
        ? useQueryAdminPartnersResearcherList
        : useQueryAdminPartnersList
  }, [isContentPartners, isResearcherPartners])

  const {
    data: adminPartnersListData,
    isError: isErrorAdminPartnersList,
    isLoading: isLoadingAdminPartnersList,
    isSuccess: isSuccessAdminPartnersList,
    refetch: refetchPartnerList
  } = listMethod(request, {
    refetchInterval: oneMinute
  })

  useEffect(() => {
    if (isSuccessAdminPartnersList) {
      store.adminPartnerList = { ...adminPartnersListData }
      setAllowExport(adminPartnersListData?.allowExport)
      setIsLoading(false)
      setConfig({ partnersStats: adminPartnersListData?.stats })
    }
  }, [isSuccessAdminPartnersList, adminPartnersListData])

  useEffect(() => {
    if (isSuccessAdminPartnersList) {
      if (adminPartnersListData?.result?.length === 0 && adminPartnersListData?.pageNumber > 1) {
        const requestForRefetch = { ...request, pageNumber: 1 }
        setRequest(requestForRefetch)
        localStorage.setItem(lsPartnersRequest, JSON.stringify(requestForRefetch))
        refetchPartnerList()
      }
    }
  }, [isSuccessAdminPartnersList, adminPartnersListData, request])

  const {
    data: partnerStatusesData,
    isError: isErrorPartnerStatuses,
    isSuccess: isSuccessPartnerStatuses
  } = usePartnerStatusesQuery({},
    {
      staleTime: twoMinutes
    })
  useEffect(() => {
    if (isSuccessPartnerStatuses) {
      setStatuses([...(partnerStatusesData?.result || [])])
    }
  }, [isSuccessPartnerStatuses, partnerStatusesData])

  // const {
  //   data: partnerSecurityStatusesData,
  //   isError: isErrorPartnerSecurityStatuses,
  //   isSuccess: isSuccessPartnerSecurityStatuses
  // } = usePartnerSecurityStatusesQuery(
  //   {
  //     staleTime: twoMinutes
  //   })
  // useEffect(() => {
  //   if (isSuccessPartnerSecurityStatuses) {
  //     setSecurityStatuses([...(partnerSecurityStatusesData?.result || [])])
  //   }
  // }, [isSuccessPartnerSecurityStatuses, partnerSecurityStatusesData])

  // const {
  //   data: partnerYoudoStatusesData,
  //   isError: isErrorPartnerYoudoStatuses,
  //   isSuccess: isSuccessPartnerYoudoStatuses
  // } = usePartnerYoudoStatusesQuery(
  //   {
  //     staleTime: twoMinutes
  //   })
  // useEffect(() => {
  //   if (isSuccessPartnerYoudoStatuses) {
  //     setYoudoStatuses([...(partnerYoudoStatusesData?.result || [])])
  //   }
  // }, [isSuccessPartnerYoudoStatuses, partnerYoudoStatusesData])

  const {
    data: partnerSmzStatusesData,
    isError: isErrorPartnerSmzStatuses,
    isSuccess: isSuccessPartnerSmzStatuses
  } = usePartnerSmzStatusesQuery(
    {
      staleTime: twoMinutes
    })
  useEffect(() => {
    if (isSuccessPartnerSmzStatuses) {
      setSmzStatuses([...(partnerSmzStatusesData?.result || [])])
    }
  }, [isSuccessPartnerSmzStatuses, partnerSmzStatusesData])

  // const {
  //   data: partnerVerificationStatusesData,
  //   isError: isErrorPartnerVerificationStatuses,
  //   isSuccess: isSuccessPartnerVerificationStatuses
  // } = usePartnerVerificationStatusesQuery(
  //   {
  //     staleTime: twoMinutes
  //   })
  // useEffect(() => {
  //   if (isSuccessPartnerVerificationStatuses) {
  //     setVerificationStatuses([...(partnerVerificationStatusesData?.result || [])])
  //   }
  // }, [isSuccessPartnerVerificationStatuses, partnerVerificationStatusesData])

  // const {
  //   data: partnerFormStatusesData,
  //   isError: isErrorPartnerFormStatuses,
  //   isSuccess: isSuccessPartnerFormStatuses
  // } = usePartnerFormStatusesQuery({
  //   staleTime: twoMinutes
  // })

  // const {
  //   data: partnerLmkFormStatusesData,
  //   isError: isErrorPartnerLmkFormStatuses,
  //   isSuccess: isSuccessPartnerLmkFormStatuses
  // } = usePartnerLmkFormStatusesQuery({
  //   staleTime: twoMinutes
  // })

  // const {
  //   data: partnerEmploymentTypeFormStatusesData,
  //   isError: isErrorPartnerEmploymentTypeFormStatuses,
  //   isSuccess: isSuccessPartnerEmploymentTypeFormStatuses
  // } = usePartnerEmploymentTypeFormStatusesQuery({
  //   staleTime: twoMinutes
  // })

  // const {
  //   data: partnerFireBriefingFormStatusesData,
  //   isError: isErrorPartnerFireBriefingFormStatuses,
  //   isSuccess: isSuccessPartnerFireBriefingFormStatuses
  // } = usePartnerFireBriefingFormStatusesQuery({
  //   staleTime: twoMinutes
  // })

  // const {
  //   data: cardStatuses,
  //   isError: isErrorCardStatuses
  // } = usePartnerCardStatusesQuery({
  //   staleTime: twoMinutes
  // })

  // const {
  //   data: requisitesStatuses,
  //   isError: isErrorRequisitesStatuses
  // } = usePartnerRequisitesStatusesQuery({
  //   staleTime: twoMinutes
  // })

  // useEffect(() => {
  //   if (isSuccessPartnerFormStatuses) {
  //     setFormStatuses([...(partnerFormStatusesData?.result || [])])
  //   }
  // }, [isSuccessPartnerFormStatuses, partnerFormStatusesData])

  // useEffect(() => {
  //   if (isSuccessPartnerLmkFormStatuses) {
  //     setLmkFormStatuses([...(partnerLmkFormStatusesData?.result || [])])
  //   }
  // }, [isSuccessPartnerLmkFormStatuses, partnerLmkFormStatusesData])

  // useEffect(() => {
  //   if (isSuccessPartnerEmploymentTypeFormStatuses) {
  //     setEmploymentTypeFormStatuses([...(partnerEmploymentTypeFormStatusesData?.result || [])])
  //   }
  // }, [isSuccessPartnerEmploymentTypeFormStatuses, partnerEmploymentTypeFormStatusesData])

  // useEffect(() => {
  //   if (isSuccessPartnerFireBriefingFormStatuses) {
  //     setFireBriefingFormStatuses([...(partnerFireBriefingFormStatusesData?.result || [])])
  //   }
  // }, [isSuccessPartnerFireBriefingFormStatuses, partnerFireBriefingFormStatusesData])

  useEffect(() => {
    if (
      isErrorPartnerStatuses ||
      isErrorAdminPartnersList ||
      // isErrorPartnerSecurityStatuses ||
      // isErrorPartnerYoudoStatuses ||
      isErrorPartnerSmzStatuses
      // isErrorPartnerVerificationStatuses ||
      // isErrorPartnerFormStatuses ||
      // isErrorPartnerLmkFormStatuses ||
      // isErrorPartnerEmploymentTypeFormStatuses ||
      // isErrorPartnerFireBriefingFormStatuses ||
      // isErrorCardStatuses ||
      // isErrorRequisitesStatuses
    ) {
      message.error('Ошибка доступа к данным')
    }
    if (isErrorAdminPartnersList) {
      setIsLoading(false)
    }
  }, [
    isErrorPartnerStatuses,
    isErrorAdminPartnersList,
    // isErrorPartnerSecurityStatuses,
    // isErrorPartnerYoudoStatuses,
    isErrorPartnerSmzStatuses
    // isErrorPartnerVerificationStatuses,
    // isErrorPartnerFormStatuses,
    // isErrorPartnerLmkFormStatuses,
    // isErrorPartnerEmploymentTypeFormStatuses,
    // isErrorPartnerFireBriefingFormStatuses,
    // isErrorCardStatuses,
    // isErrorRequisitesStatuses
  ])

  const { mutate: sendLog } = useMutateLog()

  const columns = [
    {
      title: 'Дата создания',
      dataIndex: 'registrationDate',
      key: partnerListColKeys.registrationDate,
      width: 100,
      render: x => moment(x).format('DD.MM.yyyy'),
      sorter: true,
      sortOrder: request?.orderBy?.includes('registrationDate') ? request.orderBy.replace('registrationDate ', '') + 'end' : false
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: partnerListColKeys.statuses,
      width: 150,
      filters: statuses.map(s => ({ text: s.name, value: s.id })),
      filteredValue: request?.filter?.statuses || null
    },
    // {
    //   title: 'Статус анкеты',
    //   dataIndex: 'formStatus',
    //   key: partnerListColKeys.formStatuses,
    //   width: 120,
    //   filters: formStatuses.map(s => ({ text: s.name, value: s.id })),
    //   filteredValue: request?.filter?.formStatuses || null
    // },
    // {
    //   title: 'Статус ЛМК',
    //   dataIndex: 'lmkFormStatus',
    //   key: partnerListColKeys.lmkFormStatuses,
    //   width: 120,
    //   filters: lmkFormStatuses.map(s => ({ text: s.name, value: s.id })),
    //   filteredValue: request?.filter?.lmkFormStatuses || null
    // },
    // {
    //   title: 'Смена занятости',
    //   dataIndex: 'employmentTypeFormStatus',
    //   key: partnerListColKeys.employmentTypeFormStatuses,
    //   width: 120,
    //   filters: employmentTypeFormStatuses.map(s => ({ text: s.name, value: s.id })),
    //   filteredValue: request?.filter?.employmentTypeFormStatuses || null
    // },
    // {
    //   title: 'Статус пожарный инструктаж',
    //   dataIndex: 'fireBriefingFormStatus',
    //   key: partnerListColKeys.fireBriefingFormStatuses,
    //   width: 120,
    //   filters: fireBriefingFormStatuses.map(s => ({ text: s.name, value: s.id })),
    //   filteredValue: request?.filter?.fireBriefingFormStatuses || null
    // },
    // {
    //   title: 'Банковские реквизиты',
    //   dataIndex: 'bankRequisiteFormStatus',
    //   key: partnerListColKeys.bankRequisiteFormStatuses,
    //   width: 120,
    //   filters: requisitesStatuses?.result?.map(s => ({ text: s.name, value: s.id })),
    //   filteredValue: request?.filter?.bankRequisiteFormStatuses || null
    // },
    // {
    //   title: 'Банковская карта',
    //   dataIndex: 'cardStatus',
    //   key: partnerListColKeys.cardStatuses,
    //   width: 120,
    //   filters: cardStatuses?.result?.map(s => ({ text: s.name, value: s.id })),
    //   filteredValue: request?.filter?.cardStatuses || null
    // },
    // {
    //   title: 'Статус проверки СБ',
    //   dataIndex: 'securityCheckStatus',
    //   key: 'securityCheckStatuses',
    //   filters: securityStatuses.map(s => ({ text: s.name, value: s.id })),
    //   filteredValue: request?.filter?.securityCheckStatuses || null
    // },
    {
      title: 'Статус ФНС',
      dataIndex: 'smzStatus',
      key: partnerListColKeys.smzStatuses,
      width: 120,
      filters: smzStatuses.map(s => ({ text: s.name, value: s.id })),
      filteredValue: request?.filter?.smzStatuses || null
    },
    // {
    //   title: 'Статус в Юду',
    //   dataIndex: 'youdoStatus',
    //   key: partnerListColKeys.youdoStatuses,
    //   width: 120,
    //   filters: youdoStatuses.map(s => ({ text: s.name, value: s.id })),
    //   filteredValue: request?.filter?.youdoStatuses || null
    // },
    // {
    //   title: 'Услуга',
    //   dataIndex: 'role',
    //   key: partnerListColKeys.roles,
    //   width: 120,
    //   filters: roles.map(r => ({ text: r.name, value: r.id })),
    //   filteredValue: request?.filter?.roles || null
    // },
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: partnerListColKeys.name,
      width: 180,
      ...getColumnSearchProps('name', 'по ФИО'),
      filteredValue: request?.filter?.name ? [request.filter.name] : null
    },
    {
      title: 'Телефон',
      dataIndex: 'mobilePhone',
      key: partnerListColKeys.mobilePhone,
      width: 120,
      render: (phone) =>
        <div className='break-word'>
          {phone}
        </div>,
      ...getColumnSearchProps('mobilePhone', 'по телефону', /[\d\\+]/),
      filteredValue: request?.filter?.mobilePhone ? [request.filter.mobilePhone] : null
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      key: partnerListColKeys.inn,
      width: 120,
      render: (inn) =>
        <div className='break-word'>
          {inn}
        </div>,
      ...getColumnSearchProps('inn', 'по ИНН', /[\d\\+]/),
      filteredValue: request?.filter?.inn ? [request.filter.inn] : null
    },
    // {
    //   title: 'Город',
    //   dataIndex: 'city',
    //   key: partnerListColKeys.cityIds,
    //   width: 150,
    //   // filters: cities.map(c => ({ text: c.name, value: c.id })),
    //   filteredValue: request?.filter?.cityIds || null,
    //   filterDropdown: useCallback(
    //     props => (
    //       <AutoCompleteFilter
    //         values={cities.map(c => ({ text: c.name, value: c.id }))}
    //         {...props}
    //       />
    //     ),
    //     [cities]
    //   )
    // },
    // {
    //   title: 'Магазин',
    //   dataIndex: 'storeName',
    //   key: partnerListColKeys.storeName,
    //   width: 220,
    //   ...getColumnSearchProps('storeName', 'по магазину'),
    //   filteredValue: request?.filter?.storeName ? [request.filter.storeName] : null
    // },
    {
      title: 'Продление соглашения',
      dataIndex: 'offerExpires',
      key: partnerListColKeys.offerExpires,
      width: 100,
      render: x => x ? 'Требуется' : 'Не требуется'
    }
    // {
    //   title: 'Действия при проверке',
    //   dataIndex: 'verificationStatus',
    //   key: partnerListColKeys.verificationStatuses,
    //   width: 130,
    //   filters: verificationStatuses.map(s => ({ text: s.name, value: s.name })),
    //   filteredValue: request?.filter?.verificationStatuses || null
    // },
    // {
    //   title: 'Выплаты',
    //   dataIndex: 'payoutSuspended',
    //   key: partnerListColKeys.payoutSuspended,
    //   width: 80,
    //   render: x => x ? <StopOutlined /> : <CheckOutlined />
    // }
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    const filterArrType = [
      partnerListColKeys.cityIds,
      partnerListColKeys.roles,
      partnerListColKeys.statuses,
      partnerListColKeys.smzStatuses,
      partnerListColKeys.youdoStatuses,
      partnerListColKeys.verificationStatuses,
      partnerListColKeys.formStatuses,
      partnerListColKeys.cardStatuses,
      partnerListColKeys.bankRequisiteFormStatuses,
      partnerListColKeys.lmkFormStatuses,
      partnerListColKeys.employmentTypeFormStatuses,
      partnerListColKeys.fireBriefingFormStatuses,
      'securityCheckStatuses'
    ]
    let orderBy
    if (sorter?.order) {
      orderBy = `${sorter.field} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`
    }
    const request = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      filter: Object.entries(filters || {})
        .reduce((r, c) => ({
          ...r,
          [c[0]]: c[1] ? (filterArrType.includes(c[0]) ? c[1] : c[1][0]) : undefined
        }), {}),
      orderBy
    }
    setRequest(request)
    localStorage.setItem(lsPartnersRequest, JSON.stringify(request))
    setIsLoading(true)
  }

  const handleSelectRow = (record) => ({
    onClick: () => {
      // setPartnerId(record.id)
      history.replace(generatePath(currentRoute?.idRoute, { id: record.id }))
    }
  })

  const dataSource = useMemo(() => {
    /**
     * Empty data array call error:
     * ResizeObserver loop completed with undelivered notifications
     * Hack error - set empty array of object.
     */
    return store.adminPartnerList.result?.length > 0 ? store.adminPartnerList.result : [{ isEmptyHack: true }]
  }, [store.adminPartnerList.result])

  useEffect(() => {
    if (!dataSource?.length || dataSource[0].isEmptyHack) {
      setTimeout(() => {
        setFakeEmptyData([])
      }, 100)
    } else {
      setFakeEmptyData(null)
    }
  }, [dataSource])

  useEffect(() => {
    if (user?.online && (isExternalCoordinator || isCoordinator || isRecruiter)) {
      portalButtonState?.startStatusRequesting()
    }
  }, [])

  return (
    <>
      <Spin spinning={isLoading || isLoadingAdminPartnersList} size='large'>
        <Row justify='space-between' className='partners-actions'>
          <Col>
            {allowExport &&
              <>
                <AdminDetailsExport detailType={AppConfig.report.types.bankDetails} />
                <AdminDetailsExport detailType={AppConfig.report.types.passportDetails} />
              </>}
            <PortalButton />
            <AssignPartnerButton />
            <AssignNewPartnerButton />
            <UserCitiesButton />
            <UnbanByPhone />
            <CheckUUIDPhone />
            <PartnerTakeNewPriorityButton />
          </Col>
          <Col>
            {!isExternalCoordinator && (
              <TableSettings
                options={columns.map(column => ({
                  label: column.title,
                  value: column.key
                }))}
                columnsKey={lsColumnsKey}
                onSave={setColumnsKeys}
              />
            )}
          </Col>
        </Row>
        <Table
          size='small'
          columns={columns.filter(column => columnsKeys.includes(column.key))}
          loadind={isLoading || isLoadingAdminPartnersList}
          pagination={pagination}
          rowKey={record => record?.id}
          dataSource={fakeEmptyData !== null ? fakeEmptyData : dataSource}
          onChange={handleTableChange}
          onRow={handleSelectRow}
          scroll={tableScroll}
        />
      </Spin>
      {
        !!id &&
          <Drawer
            className='idPartnerDrawer'
            width={800}
            placement='right'
            closable={false}
            onClose={() => {
              // setPartnerId(null)
              sendLog({
                targetId: `partner/${id}`,
                action: 'ClosePartnerModal'
              })
              setIsLoading(true)
              history.replace(currentRoute?.listRoute)
            }}
            visible={!!id}
            destroyOnClose
          >
            <Partner partnerId={id} />
          </Drawer>
      }
    </>
  )
}

export default withRouter(Partners)
