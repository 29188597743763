import React, { useEffect, useReducer } from 'react'
import { Divider } from 'antd'
import ZipCodeFormItem from './ZipCodeFormItem'
import CountryFormItem from './CountryFormItem'
import RegionFormItem from './RegionFormItem'
import StreetFormItem from './StreetFormItem'
import HouseFormItem from './HouseFormItem'
import ApartmentFormItem from './ApartmentFormItem'
import CityFormItem from './CityFormItem'
import SettlementFormItem from './SettlementFormItem'
import DistrictFormItem from './DistrictFormItem'
import BuildingFormItem from './BuildingFormItem'
import RegistrationSelectorFormItem from './RegistrationSelectorFormItem'
import { profileScreen } from '../../../../translates'
import { simpleReducer, isShownSberIcon, isShownTinkoffIcon } from '../../../../helper'

const RegistrationAddressBlock = ({
  size = 'large',
  headerOrientation = 'left',
  required = {},
  disabled = {},
  onChangeFormAndCheck = () => {},
  registrationFlow = false,
  initRegistrationKind = null,
  profile,
  hideBottomDivider = false
}) => {
  const [state, setState] = useReducer(simpleReducer, {
    registrationKind: initRegistrationKind
  })

  useEffect(() => {
    setState({ registrationKind: initRegistrationKind })
  }, [initRegistrationKind])

  const handleSelectRegistrationKind = (val) => {
    setState({ registrationKind: val })
    onChangeFormAndCheck()
  }

  return (
    <>
      {registrationFlow && <RegistrationSelectorFormItem required onChange={handleSelectRegistrationKind} />}
      <Divider orientation={headerOrientation}>{
        registrationFlow
          ? profileScreen.input.registrationKind.blockTitles[state.registrationKind] || 'Адрес регистрации'
          : 'Адрес регистрации'
      }
      </Divider>
      <CountryFormItem
        size={size}
        requiredRule={required?.country || false}
        disabled={disabled?.country || false}
        showSberIcon={isShownSberIcon('registrationAddress.country', profile)}
        showTinkoffIcon={isShownTinkoffIcon('registrationAddress.country', profile)}
      />
      <ZipCodeFormItem
        size={size}
        requiredRule={required?.zipCode || false}
        classNameInput='noScrollNumber'
        showSberIcon={isShownSberIcon('registrationAddress.zipCode', profile)}
        showTinkoffIcon={isShownTinkoffIcon('registrationAddress.zipCode', profile)}
      />
      <RegionFormItem
        size={size}
        requiredRule={required?.region || false}
        showSberIcon={isShownSberIcon('registrationAddress.region', profile)}
        showTinkoffIcon={isShownTinkoffIcon('registrationAddress.region', profile)}
      />
      <DistrictFormItem
        size={size}
        requiredRule={required?.district || false}
        showSberIcon={isShownSberIcon('registrationAddress.district', profile)}
        showTinkoffIcon={isShownTinkoffIcon('registrationAddress.district', profile)}
      />
      <CityFormItem
        size={size}
        requiredRule={required?.city || false}
        showSberIcon={isShownSberIcon('registrationAddress.city', profile)}
        showTinkoffIcon={isShownTinkoffIcon('registrationAddress.city', profile)}
      />
      <SettlementFormItem
        size={size}
        requiredRule={required?.settlement || false}
        showSberIcon={isShownSberIcon('registrationAddress.settlement', profile)}
        showTinkoffIcon={isShownTinkoffIcon('registrationAddress.settlement', profile)}
      />
      <StreetFormItem
        size={size}
        requiredRule={required?.street || false}
        showSberIcon={isShownSberIcon('registrationAddress.street', profile)}
        showTinkoffIcon={isShownTinkoffIcon('registrationAddress.street', profile)}
      />
      <HouseFormItem
        size={size}
        requiredRule={required?.house || false}
        showSberIcon={isShownSberIcon('registrationAddress.house', profile)}
        showTinkoffIcon={isShownTinkoffIcon('registrationAddress.house', profile)}
      />
      <BuildingFormItem
        size={size}
        requiredRule={required?.building || false}
        showSberIcon={isShownSberIcon('registrationAddress.building', profile)}
        showTinkoffIcon={isShownTinkoffIcon('registrationAddress.building', profile)}
      />
      <ApartmentFormItem
        size={size}
        requiredRule={required?.apartment || false}
        showSberIcon={isShownSberIcon('registrationAddress.apartment', profile)}
        showTinkoffIcon={isShownTinkoffIcon('registrationAddress.apartment', profile)}
      />
      {!hideBottomDivider && (<Divider />)}
    </>
  )
}

export default RegistrationAddressBlock
