import React from 'react'
import { Alert } from 'antd'
import AuthSberId from '../../../Screens/Partner/AuthScreen/AuthSberId'
import AuthTinkoffId from '../../../Screens/Partner/AuthScreen/AuthTinkoffId'
import { AppConfig } from '../../../AppConfig'
import './AuthFillButtons.css'

function AuthFillButtons () {
  return (
    <div style={{ marginTop: '0.5rem' }}>
      <Alert
        message={<b>Заполните в два касания</b>}
        description='Войдите с помощью вашего ID, и анкета заполнится автоматически. Останется только загрузить фотографии.'
        type='info'
        showIcon
      />
      <table className='authFillButtons'>
        <tr>
          <td className='left'><AuthSberId type={AppConfig.sberId.buttonType.profile} /></td>
          <td className='right'><AuthTinkoffId type={AppConfig.tinkoffId.buttonType.profile} noRecognized /></td>
        </tr>
      </table>
    </div>
  )
}

export default AuthFillButtons
