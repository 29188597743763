import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, message } from 'antd'
import { GoogleOutlined } from '@ant-design/icons'
import env from '../../../../env.json'
import { ADMIN_AUTH_PAGE, ADMIN_PARTNERS_PAGE } from '../../../../routes'
import { getHeaders } from '../../../../api/react-query/api'
import { useGoogleLogin } from '@react-oauth/google'
import { googleTokenName } from '../../../../components/Admin/AdminLogin/AdminLogin'

function GoogleAuth () {
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  const onSuccessGoogle = (tokenResponse) => {
    if (tokenResponse?.access_token) {
      fetch(env.apiEndpoint + 'admin/auth/google', {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
          ...tokenResponse
        })
      })
        .then(r => {
          if (r.status === 401) {
            history?.push(ADMIN_AUTH_PAGE)
            return Promise.resolve({})
          }
          return r.json()
        })
        .then(r => {
          setIsLoading(false)
          if (r.isSuccess) {
            if (process.env.NODE_ENV === 'development') {
              document.cookie = 'user=' + tokenResponse?.access_token
              localStorage.setItem(googleTokenName, tokenResponse?.access_token)
            }
            history.push(ADMIN_PARTNERS_PAGE)
          } else {
            message.error('Неудачная попытка авторизации')
          }
        })
        .catch(e => {
          setIsLoading(false)
          console.log(e)
          message.error('Неудачная попытка авторизации')
        })
    }
  }

  const loginGoogle = useGoogleLogin({
    onSuccess: onSuccessGoogle
  })

  const login = () => {
    setIsLoading(true)
    loginGoogle()
  }

  return (
    <Button icon={<GoogleOutlined />} loading={isLoading} type='primary' onClick={login}>Войти c Google</Button>
  )
}

export default GoogleAuth
