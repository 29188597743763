import React, { useMemo } from 'react'
import Documents from './Documents'
import { profileScreen } from '../../../translates'
import { useStores } from '../../../Store/Store'
import { AppConfig } from '../../../AppConfig'
import DrivingNumberFormItem from '../../Common/FormItems/Driving/DrivingNumberFormItem'
import DrivingIssuerNameFormItem from '../../Common/FormItems/Driving/DrivingIssuerNameFormItem'
import { Divider } from 'antd'
import DrivingIssueDateFormItem from '../../Common/FormItems/Driving/DrivingIssueDateFormItem'

function DrivingDetails ({
  form,
  trigger,
  handleUpdateImage = () => {},
  handleUploadingImage = () => {},
  handleDeleteImage = () => {},
  handleUpdateForm = () => {},
  filterDocuments = () => {},
  headerOrientation = 'left',
  size = 'large',
  hideDocs = false,
  hideBottomDivider = false,
  notRequired = false
}) {
  const {
    partnerProfile
  } = useStores()

  const drivingDocumentList = useMemo(() => AppConfig.documentNameList
    .filter(d => [
      profileScreen.input.drivingLicencePhoto1.name,
      profileScreen.input.drivingLicencePhoto2.name
    ].includes(d)), [])

  return (
    <>
      <Divider orientation={headerOrientation}>Водительское удостоверение</Divider>

      <DrivingNumberFormItem size={size} requiredRule={!notRequired} />
      <DrivingIssuerNameFormItem size={size} requiredRule={!notRequired} />
      <DrivingIssueDateFormItem size={size} requiredRule={!notRequired} />

      {!hideDocs && (
        <Documents
          title={null}
          hidePhotoDoc
          documents={drivingDocumentList}
          trigger={trigger}
          form={form}
          required={partnerProfile?.required}
          filterDocuments={filterDocuments}
          onUpdate={handleUpdateImage}
          onUploading={handleUploadingImage}
          onDelete={handleDeleteImage}
          onPhotoDoc={handleUpdateForm}
        />
      )}

      {!hideBottomDivider && (<Divider />)}
    </>
  )
}

export default DrivingDetails
