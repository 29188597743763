import React, { useMemo } from 'react'
import { Col, Row } from 'antd'
import { AppConfig } from '../../../AppConfig'

const validList = [
  AppConfig.partnerStatTypes.waitingPartners,
  AppConfig.partnerStatTypes.verifiedPartners
]

function PartnerHeader ({ statType }) {
  const span = useMemo(() => {
    return validList.includes(statType) ? 6 : 12
  }, [statType])

  return (
    <Row>
      <Col span={span}>ФИО</Col>
      {validList.includes(statType) && (<Col span={span}>Статус</Col>)}
      <Col span={span}>Время статуса</Col>
      {validList.includes(statType) && (<Col span={span}>В работе</Col>)}
    </Row>
  )
}

export default PartnerHeader
