import React, { useContext, useEffect, useReducer, useRef } from 'react'
import { ConfigContext } from '../../../context/configContext'
import { Button, Modal, Spin, Space, Form, Input, message, Typography } from 'antd'
import { simpleReducer, validatePhone } from '../../../helper'
import { profileScreen } from '../../../translates'
import { useMutateCheckUuid } from '../../../api/react-query/adminPartners'
import { generatePath, Link } from 'react-router-dom'
import { ADMIN_PARTNER_PAGE } from '../../../routes'

const { Title, Text } = Typography

const initialState = {
  isModalOpen: false,
  dataResult: null
}

function CheckUUIDPhone () {
  const { isAdmin, isCoordinator, isRecruiter } = useContext(ConfigContext)
  const [form] = Form.useForm()
  const [state, setState] = useReducer(simpleReducer, initialState)
  const inputRef = useRef()

  const {
    mutate: checkUuid,
    data: dataCheckUuid,
    isSuccess: isSuccessCheckUuid,
    isError: isErrorCheckUuid,
    isLoading: isLoadingCheckUuid
  } = useMutateCheckUuid()

  useEffect(() => {
    if (isSuccessCheckUuid && dataCheckUuid?.isSuccess) {
      setState({ dataResult: dataCheckUuid })
    } else if (isErrorCheckUuid || dataCheckUuid?.errorMessage) {
      message.error(dataCheckUuid?.errorMessage || 'Ошибка отправки данных')
    }
  }, [isSuccessCheckUuid, isErrorCheckUuid, dataCheckUuid])

  const handleClick = () => {
    setState({ isModalOpen: true })
  }

  const handleClose = () => {
    setState({ isModalOpen: false })
  }

  const handleSave = () => {
    form.validateFields().then(fields => {
      checkUuid({ phone: fields.phone })
    }).catch(() => {
      console.log('fail')
    })
  }

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue({ phone: '+' })
    setState({ dataResult: null })
    setTimeout(() => inputRef.current?.focus(), 300)
  }, [state.isModalOpen])

  const getPartnerContent = partner => {
    if (!partner) {
      return 'Номер свободен'
    }
    return (
      <>
        <Text style={{ marginRight: '1rem' }}>Телефон: {partner.mobilePhone}</Text>
        <Text style={{ marginRight: '1rem' }}>UUID: {partner.uuid ?? 'не задан'}</Text>
        <Link to={generatePath(ADMIN_PARTNER_PAGE, { id: partner.id })}>Ссылка</Link>
        <br />
        <Text type='danger'>Партнер найден замена на указанный номер телефона не возможна</Text>
      </>
    )
  }

  const getUuidContent = uuid => {
    if (!uuid) {
      return 'Запись отсутствует'
    }
    return (
      <>
        <Text style={{ marginRight: '1rem' }}>Телефон: {uuid.mobilePhone}</Text>
        <Text style={{ marginRight: '1rem' }}>UUID: {uuid.uuid ?? 'не задан'}</Text>
        <br />
        <Text type='danger'>Замена не возможна для номера телефона задан UUID</Text>
      </>
    )
  }

  const DISABLE_BUTTON = true
  if (DISABLE_BUTTON || (!isAdmin && !isCoordinator && !isRecruiter)) {
    return null
  }

  return (
    <Space style={{ marginBottom: isAdmin ? 16 : 0 }}>
      <Button
        loading={isLoadingCheckUuid}
        onClick={handleClick}
      >
        Проверить телефон
      </Button>
      {
        state.isModalOpen && (
          <Modal
            width={600}
            visible={state.isModalOpen}
            onCancel={handleClose}
            onOk={handleSave}
            okText='Проверить'
            title='Проверить телефон'
            destroyOnClose
          >
            <Spin spinning={isLoadingCheckUuid}>
              <Form
                form={form}
              >
                <Form.Item
                  label='Номер телефона'
                  name={['phone']}
                  rules={[
                    { required: true, message: profileScreen.input.mobilePhone.message },
                    { validator: validatePhone, message: profileScreen.input.mobilePhone.message }
                  ]}
                >
                  <Input placeholder={profileScreen.input.mobilePhone.placeholder} ref={inputRef} />
                </Form.Item>
              </Form>
              {
                state.dataResult && (
                  <>
                    <Title level={5}>Найден партнер</Title>
                    {getPartnerContent(state.dataResult?.partner)}
                    <br /><br />
                    <Title level={5}>Запись PartnerUUID</Title>
                    {getUuidContent(state.dataResult?.uuid)}
                  </>
                )
              }
            </Spin>
          </Modal>
        )
      }
    </Space>
  )
}

export default CheckUUIDPhone
