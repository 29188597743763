import { Form } from 'antd'
import React from 'react'
import { errors, profileScreen } from '../../../../translates'
import { MaskedInput } from 'antd-masked-input'
import { validateFullSnils, validateSnils } from '../../../../helper'

const SnilsFormItem = ({
  label = profileScreen.input.snils.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.snils.message,
  size = 'large',
  placeholder = profileScreen.input.snils.placeholder,
  validator,
  classNameInput = '',
  onChange = () => {},
  disabled = false
}) => {
  return (
    <Form.Item
      name={['personalData', profileScreen.input.snils.name]}
      label={label}
      rules={rules ?? [
        {
          required: requiredRule,
          message: messageRule,
          ...(validator ? { validator } : { validator: validateSnils })
        },
        {
          message: errors.snilsCheckSum,
          ...(validator ? { validator } : { validator: (rule, value) => validateFullSnils({ rule, snils: value }) })

        }
      ]}
    >
      <MaskedInput
        size={size}
        placeholder={placeholder}
        mask='111-111-111 11'
        className={classNameInput}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  )
}
export default SnilsFormItem
