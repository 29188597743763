import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { Layout, Typography, Steps, Spin } from 'antd'
import { observer } from 'mobx-react'

import MobileHeader from '../../../components/Partner/MobileHeader/MobileHeader'
import Profile from '../../../components/Partner/Profile/Profile'
import './ProfileMainStyle.css'
import Offer from '../../../components/Partner/Offer/Offer'
import Success from '../../../components/Partner/Success/Success'
import PolicyScreen from '../PolicyScreen/PolicyScreen'
import { useStores } from '../../../Store/Store'
import { useDataApi } from '../../../api/useDataApi'
import { AppConfig, partnerStatusCodes } from '../../../AppConfig'
import { getHeaders } from '../../../api/react-query/api'
import { useLocation } from 'react-router-dom'
import ProfileEditInfoScreen from './ProfileEditInfoScreen'
import OfferSimple from '../../../components/Partner/Offer/OfferSimple'
import ProfileEditCardScreen from './ProfileEditCardScreen'
import PhotoDoc from '../../../components/Partner/PhotoDoc/PhotoDoc'
import { simpleReducer } from '../../../helper'
import ProfileEditLmkScreen from './ProfileEditLmkScreen'
import ProfileSelfEmployed from '../../../components/Partner/Profile/ProfileSelfEmployed'
import PolicyAgreement from '../../../components/Partner/PolicyAgreement/PolicyAgreement'
import { partnerRoles4MultiOffer } from '../../../Constants'
import ProfileEditFireBriefingScreen from './ProfileEditFireBriefingScreen'
import ProfileEditEmploymentTypeScreen from './ProfileEditEmploymentTypeScreen'
import BankAccept from '../../../components/Partner/BankAccept/BankAccept'

const { Content } = Layout
const { Title } = Typography
const { Step } = Steps

const translates = {
  driverMainScreen: {
    title: 'Заполнение анкеты'
  }
}

const getScreen = profile => {
  if (profile?.statusCode === partnerStatusCodes.loginIssued && profile?.payoutSuspended) return AppConfig.partnerSteps.stepSuccess
  if (profile?.statusCode === partnerStatusCodes.loginIssued && profile?.offerExpires) return AppConfig.partnerSteps.stepOffer
  if (profile?.statusCode === partnerStatusCodes.loginIssued &&
    partnerRoles4MultiOffer.includes(profile?.personalData?.roleCode) &&
    (!profile?.cyberSecurityPolicy?.isValid || !profile?.assemblyAndDeliveryPolicy?.isValid)
  ) {
    return AppConfig.partnerSteps.stepOffer
  }
  if ([partnerStatusCodes.profileFilled,
    partnerStatusCodes.verification,
    partnerStatusCodes.verified,
    partnerStatusCodes.loginIssued
  ].includes(profile?.statusCode) &&
    profile?.lmkDetails?.hasMedicalBook &&
    !profile?.lmkDetails?.agreementAccepted) {
    return AppConfig.partnerSteps.stepOffer
  }

  if ((profile?.statusCode === partnerStatusCodes.loginIssued || profile?.statusCode === partnerStatusCodes.blocked) &&
    !profile?.offerExpires && !profile?.renewAllowed
  ) return AppConfig.partnerSteps.stepSuccess
  switch (profile?.statusCode) {
    case partnerStatusCodes.new:
    case partnerStatusCodes.draft:
    case partnerStatusCodes.free:
    case partnerStatusCodes.correction: return AppConfig.partnerSteps.stepProfile
    case partnerStatusCodes.profileFilled: return AppConfig.partnerSteps.stepOffer
    case partnerStatusCodes.verification:
    case partnerStatusCodes.verified:
    case partnerStatusCodes.loginIssued:
    case partnerStatusCodes.loginNotFound:
    case partnerStatusCodes.rejected: return AppConfig.partnerSteps.stepSuccess
    default: return AppConfig.partnerSteps.stepProfile
  }
}

export const PROFILE_CARD_STATE_INIT = {
  form: null,
  storeData: null
}

export const PROFILE_EDITINFO_STATE_INIT = {
  form: null,
  sessionChangedFields: []
}

export const PROFILE_LMK_STATE_INIT = {
  form: null
}

export const PROFILE_EMPLOYMENT_TYPE_STATE_INIT = {
  form: null
}

const ProfileMainScreen = () => {
  const store = useStores()
  const location = useLocation()
  const currentScreen = getScreen(store?.partnerProfile?.profile)
  const [current, setCurrent] = useState(currentScreen)
  const [profileCardState, setProfileCardState] = useReducer(simpleReducer, PROFILE_CARD_STATE_INIT)
  const [profileEditInfoState, setProfileEditInfoState] = useReducer(simpleReducer, PROFILE_EDITINFO_STATE_INIT)
  const [profileLmkState, setProfileLmkState] = useReducer(simpleReducer, PROFILE_LMK_STATE_INIT)
  const [profileEmploymentTypeState, setProfileEmploymentTypeState] = useReducer(simpleReducer, PROFILE_EMPLOYMENT_TYPE_STATE_INIT)

  const [{ data, isLoading }] = useDataApi({
    initialUrl: store?.partnerProfile?.profile?.personalData?.mobilePhone ? '' : 'partner/profile',
    initialParams: {
      method: 'GET',
      headers: getHeaders()
    }
  })
  useEffect(() => {
    if (data?.isSuccess && !store?.partnerProfile?.profile?.personalData?.mobilePhone) {
      store?.partnerProfile?.setPartnerProfile(data?.partner)
      const currentScreen = getScreen(data?.partner?.profile)
      setCurrent(currentScreen)
    }
  }, [data, store])

  /**
   * Если пользователь обновил страницу ожидаем профиль с сервера "data"
   * Если пользователь перешел с экрана логина ищем профиль в "store?.partnerProfile"
   */
  const profile = store?.partnerProfile?.profile?.personalData ? store?.partnerProfile?.profile : data?.partner?.profile

  const isContentPartner = useMemo(() => {
    return profile?.personalData?.roleCode === AppConfig.partnerRoles.szContent
  }, [profile])

  const isResearcherPartner = useMemo(() => {
    return profile?.personalData?.roleCode === AppConfig.partnerRoles.researcher
  }, [profile])

  const steps = {
    [AppConfig.partnerSteps.stepProfile]: {
      title: '',
      content: <Profile onSuccess={setCurrent} />,
      contentRoleContent: <ProfileSelfEmployed onSuccess={setCurrent} />
    },
    [AppConfig.partnerSteps.stepOffer]: {
      title: '',
      content: <Offer onSuccess={setCurrent} />
    },
    [AppConfig.partnerSteps.stepSuccess]: {
      title: '',
      content: <Success />
    }

  }

  if (profile?.tinkoffData?.bankConfirmRequired) {
    return <BankAccept profile={profile} setStep={setCurrent} />
  }
  const isNeedPolicyAgreement = !profile?.personalDataPolicy?.isValid || !profile?.electronicSignaturePolicy?.isValid
  if (location.search === `?action=${AppConfig.formScreenActions.editInfo}`) {
    return <ProfileEditInfoScreen extState={profileEditInfoState} setExtState={setProfileEditInfoState} />
  }
  if (location.search === `?action=${AppConfig.formScreenActions.editCard}`) {
    return <ProfileEditCardScreen extState={profileCardState} setExtState={setProfileCardState} />
  }
  if (location.search === `?action=${AppConfig.formScreenActions.editLmk}`) {
    return <ProfileEditLmkScreen extState={profileLmkState} setExtState={setProfileLmkState} />
  }
  if (location.search === `?action=${AppConfig.formScreenActions.editFireBriefing}`) {
    return <ProfileEditFireBriefingScreen />
  }
  if (location.search === `?action=${AppConfig.formScreenActions.editEmploymentType}`) {
    return <ProfileEditEmploymentTypeScreen extState={profileEmploymentTypeState} setExtState={setProfileEmploymentTypeState} />
  }
  if (location.search.includes(`?action=${AppConfig.formScreenActions.offerType}`)) {
    return <PolicyAgreement profileLmkState={profileLmkState} profileEditInfoState={profileEditInfoState} />
  }
  if (location.search.includes(`?action=${AppConfig.formScreenActions.offer}`)) {
    return <OfferSimple />
  }
  if (location.search.includes(`?action=${AppConfig.formScreenActions.photoDoc}`)) {
    return <PhotoDoc />
  }
  return (
    <Layout className='ProfileWrapper'>
      <MobileHeader />
      <Spin spinning={isLoading} size='large'>
        {isNeedPolicyAgreement
          ? <PolicyScreen />
          : (
            <Content>
              <Title level={4}>{translates.driverMainScreen.title}</Title>
              <Steps current={current} className={profile?.payoutSuspended && 'danger-step'}>
                {Object.keys(steps).map(item => (
                  <Step key={steps[item].title} title={steps[item].title} />
                ))}
              </Steps>
              <div className='steps-content'>
                {
                  (isContentPartner || isResearcherPartner) && current === AppConfig.partnerSteps.stepProfile
                    ? steps[current].contentRoleContent
                    : steps[current].content
                }
              </div>
            </Content>
          )}
      </Spin>
    </Layout>
  )
}
export default observer(ProfileMainScreen)
