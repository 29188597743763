import React from 'react'

import ProfileMainScreen from './Screens/Partner/ProfileScreen/ProfileMainScreen'
import ProfileContractScreen from './Screens/Partner/ProfileScreen/ProfileContractScreen'
import PolicyAgreement from './components/Partner/PolicyAgreement/PolicyAgreement'
import Act from './components/Partner/Act/Act'
import ClothingSize from './components/Partner/ClothingSize/ClothingSize'

import AdminLayout from './components/Admin/AdminLayout/AdminLayout'
import {
  AdminAuth,
  // AssetsReport,
  PartnersReport,
  // Assets,
  // ActTemplates,
  Users,
  Partners,
  BannedPartners
  // StoreManagement,
  // UserStores
} from './Screens/Admin'
import { AppConfig } from './AppConfig'
import { policyTypes } from './Constants'
import HistoryReport from './Screens/Admin/Reports/HistoryReport'
// import LmkReport from './Screens/Admin/Reports/LmkReport'
import Reports from './Screens/Admin/Reports/Reports'
import DeclineReasons from './Screens/Admin/DeclineReasons/DeclineReasons'
// import VirtualCardReport from './Screens/Admin/Reports/VirtualCardReport'
// import FireBriefingReport from './Screens/Admin/Reports/FireBriefingReport'
// import GivenVirtualCardReport from './Screens/Admin/Reports/GivenVirtualCardReport'
import PartnersOIDC from './components/Partner/PartnersOIDC/PartnersOIDC'
import PartnersTinkoffId from './components/Partner/PartnersTinkoffId/PartnersTinkoffId'
// import CoordinatorsTimeReport from './Screens/Admin/Reports/CoordinatorsTimeReport'
// import BankRequisiteReport from './Screens/Admin/Reports/BankRequisiteReport'

export const PROFILE_MAIN_PAGE = '/profile'
export const PROFILE_CONTRACT_PAGE = '/profile/contract'
export const PARTNER_ACT_PAGE = '/act/:token'
export const PARTNER_CLOTHING_SIZE_PAGE = '/size/:token'
export const PERSONAL_DATA_POLICY_PAGE = `/${policyTypes.personalDataPolicy}`
export const PARTNERS_SBER_MARKET_POLICY_PAGE = `/${policyTypes.partnersSberMarketPolicy}`
export const ELECTRONIC_SIGNATURE_POLICY_PAGE = `/${policyTypes.electronicSignaturePolicy}`

export const PARTNERS_OIDC_PAGE = '/partners/oidc'
export const PARTNERS_TINKOFF_ID_PAGE = '/partners/tinkoffid'

export const ADMIN_AUTH_PAGE = '/admin'
export const ADMIN_PARTNER_PAGE = '/admin/partners/:id'
export const ADMIN_PARTNERS_PAGE = '/admin/partners'
export const ADMIN_PARTNER_CONTENT_PAGE = '/admin/partnersContent/:id'
export const ADMIN_PARTNERS_CONTENT_PAGE = '/admin/partnersContent'
export const ADMIN_PARTNER_RESEARCHER_PAGE = '/admin/partnersResearcher/:id'
export const ADMIN_PARTNERS_RESEARCHER_PAGE = '/admin/partnersResearcher'
export const ADMIN_BANNED_PARTNER_PAGE = '/admin/bannedPartners/:id'
export const ADMIN_BANNED_PARTNERS_PAGE = '/admin/bannedPartners'
export const ADMIN_STORE_MANAGEMENT_PAGE = '/admin/storeManagement'
export const ADMIN_USERS_PAGE = '/admin/users'
export const ADMIN_USER_PAGE = '/admin/users/:id'
export const ADMIN_USER_STORES_PAGE = '/admin/userStores'
export const ADMIN_PARTNERS_REPORT_PAGE = '/admin/partnersReport'
export const ADMIN_PARTNERS_CONTENT_REPORT_PAGE = '/admin/partnersContentReport'
export const ADMIN_PARTNERS_RESEARCHER_REPORT_PAGE = '/admin/partnersResearcherReport'
export const ADMIN_ASSETS_REPORT_PAGE = '/admin/assetsReport'
export const ADMIN_FIRE_BRIEFING_REPORT_PAGE = '/admin/fireBriefingReport'
export const ADMIN_GIVEN_VIRTUAL_CARD_REPORT_PAGE = '/admin/givenVirtualCardReport'
export const ADMIN_HISTORY_REPORT_PAGE = '/admin/historyReport'
export const ADMIN_COORDINATORS_TIME_REPORT_PAGE = '/admin/coordinatorsTimeReport'
export const ADMIN_BANK_REQUISITE_REPORT_PAGE = '/admin/bankRequisiteReport'
export const ADMIN_LMK_REPORT_PAGE = '/admin/lmkReport'
export const ADMIN_VIRTUAL_CARD_REPORT_PAGE = '/admin/virtualCardReport'
export const ADMIN_ASSETS_PAGE = '/admin/assets'
export const ADMIN_ACT_TEMPLATES_PAGE = '/admin/actTemplates'
export const ADMIN_REPORTS_PAGE = '/admin/reports'
export const ADMIN_DECLINE_REASONS_PAGE = '/admin/declineReasons'

export const routes = {
  PartnerProfile: [
    {
      path: PROFILE_MAIN_PAGE,
      exact: true,
      component: ProfileMainScreen,
      title: ''
    },
    {
      path: PROFILE_CONTRACT_PAGE,
      exact: true,
      component: ProfileContractScreen,
      title: ''
    },
    {
      path: PARTNERS_OIDC_PAGE,
      exact: true,
      publicRoute: true,
      component: PartnersOIDC,
      title: ''
    },
    {
      path: PARTNERS_TINKOFF_ID_PAGE,
      exact: true,
      publicRoute: true,
      component: PartnersTinkoffId,
      title: ''
    },
    {
      path: PERSONAL_DATA_POLICY_PAGE,
      exact: true,
      publicRoute: true,
      component: PolicyAgreement,
      title: ''
    },
    {
      path: PARTNERS_SBER_MARKET_POLICY_PAGE,
      exact: true,
      publicRoute: true,
      component: PolicyAgreement,
      title: ''
    },
    {
      path: ELECTRONIC_SIGNATURE_POLICY_PAGE,
      exact: true,
      publicRoute: true,
      component: PolicyAgreement,
      title: ''
    },
    {
      path: PARTNER_ACT_PAGE,
      exact: true,
      publicRoute: true,
      component: Act,
      title: ''
    },
    {
      path: PARTNER_CLOTHING_SIZE_PAGE,
      exact: true,
      publicRoute: true,
      component: ClothingSize,
      title: ''
    }
  ],

  Admin: [
    {
      path: ADMIN_AUTH_PAGE,
      exact: true,
      component: () => <AdminAuth />,
      title: 'Вход',
      hide: true
    },
    {
      path: ADMIN_PARTNERS_PAGE,
      exact: true,
      component: () => (
        <AdminLayout>
          <Partners />
        </AdminLayout>),
      title: 'Партнеры',
      roles: [
        AppConfig.groups.administrators,
        AppConfig.groups.security,
        AppConfig.groups.recruiter,
        AppConfig.groups.operations
      ]
    },
    {
      path: ADMIN_PARTNER_PAGE,
      exact: true,
      component: () => (
        <AdminLayout>
          <Partners />
        </AdminLayout>),
      title: 'Партнер',
      hide: true,
      roles: [
        AppConfig.groups.administrators,
        AppConfig.groups.security,
        AppConfig.groups.recruiter,
        AppConfig.groups.operations
      ]
    },
    // {
    //   path: ADMIN_PARTNERS_CONTENT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <Partners />
    //     </AdminLayout>),
    //   title: 'Партнеры-контент',
    //   roles: [AppConfig.groups.contentcoordinators]
    // },
    // {
    //   path: ADMIN_PARTNER_CONTENT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <Partners />
    //     </AdminLayout>),
    //   title: 'Партнер-контент',
    //   hide: true,
    //   roles: [AppConfig.groups.contentcoordinators]
    // },
    // {
    //   path: ADMIN_PARTNERS_RESEARCHER_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <Partners />
    //     </AdminLayout>),
    //   title: 'Партнеры-ресерч',
    //   roles: [AppConfig.groups.researchercoordinators]
    // },
    // {
    //   path: ADMIN_PARTNER_RESEARCHER_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <Partners />
    //     </AdminLayout>),
    //   title: 'Партнер-ресерч',
    //   hide: true,
    //   roles: [AppConfig.groups.researchercoordinators]
    // },
    // {
    //   path: ADMIN_STORE_MANAGEMENT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <StoreManagement />
    //     </AdminLayout>),
    //   title: 'Управление магазинами',
    //   roles: [AppConfig.groups.administrators]
    // },
    {
      path: ADMIN_USERS_PAGE,
      exact: true,
      component: () => (
        <AdminLayout>
          <Users />
        </AdminLayout>),
      title: 'Пользователи системы',
      roles: [AppConfig.groups.administrators]
    },
    {
      path: ADMIN_BANNED_PARTNERS_PAGE,
      exact: true,
      component: () => (
        <AdminLayout>
          <BannedPartners />
        </AdminLayout>),
      title: 'Забаненные партнеры',
      roles: [
        AppConfig.groups.administrators,
        AppConfig.groups.security,
        AppConfig.groups.recruiter
      ]
    },
    {
      path: ADMIN_BANNED_PARTNER_PAGE,
      exact: true,
      component: () => (
        <AdminLayout>
          <BannedPartners />
        </AdminLayout>),
      title: 'Забаненный партнер',
      hide: true,
      roles: [
        AppConfig.groups.administrators,
        AppConfig.groups.security,
        AppConfig.groups.recruiter
      ]
    },
    {
      path: ADMIN_USER_PAGE,
      exact: true,
      component: () => (
        <AdminLayout>
          <Users />
        </AdminLayout>),
      title: 'Пользователь',
      hide: true,
      roles: [AppConfig.groups.administrators]
    },
    // {
    //   path: ADMIN_ASSETS_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <Assets />
    //     </AdminLayout>),
    //   title: 'Имущество',
    //   roles: [AppConfig.groups.administrators]
    // },
    // {
    //   path: ADMIN_ACT_TEMPLATES_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <ActTemplates />
    //     </AdminLayout>),
    //   title: 'Шаблоны актов',
    //   roles: [AppConfig.groups.administrators]
    // },
    {
      path: ADMIN_DECLINE_REASONS_PAGE,
      exact: true,
      component: () => (
        <AdminLayout>
          <DeclineReasons />
        </AdminLayout>),
      title: 'Шаблоны ответов',
      roles: [
        AppConfig.groups.administrators
      ]
    },
    // {
    //   path: ADMIN_USER_STORES_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <UserStores />
    //     </AdminLayout>),
    //   title: 'Мои магазины',
    //   // roles: [AppConfig.groups.supervisors]
    //   roles: []
    // },
    {
      path: ADMIN_PARTNERS_REPORT_PAGE,
      exact: true,
      component: () => (
        <AdminLayout>
          <PartnersReport />
        </AdminLayout>),
      title: 'Отчет по партнерам',
      roles: [
        AppConfig.groups.administrators,
        AppConfig.groups.security,
        AppConfig.groups.operations
      ]
    },
    // {
    //   path: ADMIN_PARTNERS_CONTENT_REPORT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <PartnersReport />
    //     </AdminLayout>),
    //   title: 'Отчет партнеры-контент',
    //   roles: [
    //     AppConfig.groups.administrators,
    //     AppConfig.groups.contentcoordinators,
    //     AppConfig.groups.coordinators
    //   ]
    // },
    // {
    //   path: ADMIN_PARTNERS_RESEARCHER_REPORT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <PartnersReport />
    //     </AdminLayout>),
    //   title: 'Отчет партнеры-ресерч',
    //   roles: [
    //     AppConfig.groups.administrators,
    //     AppConfig.groups.researchercoordinators,
    //     AppConfig.groups.coordinators
    //   ]
    // },
    // {
    //   path: ADMIN_ASSETS_REPORT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <AssetsReport />
    //     </AdminLayout>),
    //   title: 'Отчет по имуществу',
    //   roles: [
    //     AppConfig.groups.administrators
    //   ]
    // },
    {
      path: ADMIN_HISTORY_REPORT_PAGE,
      exact: true,
      component: () => (
        <AdminLayout>
          <HistoryReport />
        </AdminLayout>),
      title: 'Отчет "История данных"',
      roles: [
        AppConfig.groups.administrators
      ]
    },
    // {
    //   path: ADMIN_LMK_REPORT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <LmkReport />
    //     </AdminLayout>),
    //   title: 'Отчет ЛМК',
    //   roles: [
    //     AppConfig.groups.administrators,
    //     AppConfig.groups.citymanagers,
    //     AppConfig.groups.supervisors,
    //     AppConfig.groups.lmkcontrollers,
    //     AppConfig.groups.partnersupport
    //   ]
    // },
    // {
    //   path: ADMIN_VIRTUAL_CARD_REPORT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <VirtualCardReport />
    //     </AdminLayout>),
    //   title: 'Отчет по анкетам ВК',
    //   roles: [
    //     AppConfig.groups.administrators,
    //     AppConfig.groups.supervisors,
    //     AppConfig.groups.coordinators,
    //     AppConfig.groups.citymanagers,
    //     AppConfig.groups.partnersupport
    //   ]
    // },
    // {
    //   path: ADMIN_FIRE_BRIEFING_REPORT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <FireBriefingReport />
    //     </AdminLayout>),
    //   title: 'Отчет пожарный инструктаж',
    //   roles: [
    //     AppConfig.groups.administrators,
    //     AppConfig.groups.supervisors,
    //     AppConfig.groups.citymanagers,
    //     AppConfig.groups.partnersupport
    //   ]
    // },
    // {
    //   path: ADMIN_GIVEN_VIRTUAL_CARD_REPORT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <GivenVirtualCardReport />
    //     </AdminLayout>),
    //   title: 'Отчет по выданным ВК',
    //   roles: [
    //     AppConfig.groups.administrators,
    //     AppConfig.groups.partnersupport
    //   ]
    // },
    // {
    //   path: ADMIN_COORDINATORS_TIME_REPORT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <CoordinatorsTimeReport />
    //     </AdminLayout>),
    //   title: 'Отчет скорость проверки',
    //   roles: [
    //     AppConfig.groups.administrators
    //   ]
    // },
    // {
    //   path: ADMIN_BANK_REQUISITE_REPORT_PAGE,
    //   exact: true,
    //   component: () => (
    //     <AdminLayout>
    //       <BankRequisiteReport />
    //     </AdminLayout>),
    //   title: 'Отчет заполнения БР',
    //   roles: [
    //     AppConfig.groups.administrators
    //   ]
    // },
    {
      path: ADMIN_REPORTS_PAGE,
      exact: true,
      component: () => (
        <AdminLayout>
          <Reports />
        </AdminLayout>),
      title: 'Отчеты',
      roles: [
        AppConfig.groups.coordinators,
        AppConfig.groups.administrators
      ]
    }
  ]
}
