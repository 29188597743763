import { Form } from 'antd'
import React from 'react'
import { errors, profileScreen } from '../../../../translates'
import { MaskedInput } from 'antd-masked-input'
import { validateDate, validateDateNotFuture } from '../../../../helper'

const DrivingIssueDateFormItem = ({
  label = profileScreen.input.drivingLicenceIssueDate.label,
  rules = undefined,
  requiredRule = true,
  messageRule = profileScreen.input.drivingLicenceIssueDate.message,
  size = 'large',
  placeholder = profileScreen.input.drivingLicenceIssueDate.placeholder,
  validator,
  classNameInput = '',
  onChange = () => {},
  disabled = false
}) => {
  return (
    <Form.Item
      name={['personalData', 'drivingLicence', profileScreen.input.drivingLicenceIssueDate.name]}
      label={label}
      rules={rules ?? [
        {
          required: requiredRule,
          message: messageRule,
          ...(validator ? { validator } : { validator: validateDate })
        },
        {
          message: errors.futureDate,
          validator: (rule, value) => validateDateNotFuture({ rule, date: value })
        }
      ]}
    >
      <MaskedInput
        size={size}
        placeholder={placeholder}
        mask='11.11.1111'
        className={classNameInput}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  )
}
export default DrivingIssueDateFormItem
