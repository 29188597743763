import React, { useEffect, useState } from 'react'
import { Layout, Button, PageHeader, Spin, Row, Form, Select, message } from 'antd'

import './ClothingSize.css'
import MobileHeader from '../MobileHeader/MobileHeader'
import { useParams } from 'react-router-dom'
import { profileScreen } from '../../../translates'
import { useClothingSizes } from '../../../hooks'
import { useMutateClothingSize } from '../../../api/react-query/partners'
import { formLayout } from '../../../Constants'

const { Option } = Select

const ClothingSize = () => {
  const [form] = Form.useForm()
  const { token } = useParams()
  const { data: clothingSizes } = useClothingSizes(token)
  const [size, setSize] = useState(null)
  const {
    mutate: mutateClothingSize,
    data: dataClothingSize,
    isLoading: isLoadingClothingSize,
    isError: isErrorClothingSize,
    isSuccess: isSuccessClothingSize
  } = useMutateClothingSize()

  useEffect(() => {
    if (isErrorClothingSize) {
      message.error('Ошибка смены размера одежды.')
    }
  }, [isErrorClothingSize])

  useEffect(() => {
    if (isSuccessClothingSize) {
      if (dataClothingSize?.errorMessage) {
        return message.error(dataClothingSize?.errorMessage || 'Ошибка смены размера одежды.')
      }
      message.success('Размер одежды успешно изменен.')
    }
  }, [dataClothingSize, isSuccessClothingSize])

  return (
    <Layout className='ClothingSize'>
      <MobileHeader />
      <Spin spinning={!clothingSizes?.length} size='large'>
        <Form
          {...formLayout}
          form={form}
          onFinish={(values) => {
            mutateClothingSize({ sizeId: values.clothingSize, token })
          }}
        >
          <Row justify='space-around'>
            <PageHeader title={profileScreen.input.clothingSize.label} />
          </Row>
          <Form.Item
            className='text-left'
            name='clothingSize'
            label={profileScreen.input.clothingSize.label}
            rules={[{ required: true, message: profileScreen.input.clothingSize.message }]}
          >
            <Select
              size='large'
              placeholder={profileScreen.input.clothingSize.placeholder}
              onChange={setSize}
            >
              {
                clothingSizes.map(c => <Option key={c.name} value={c.id}>{c.name}</Option>)
              }
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              block
              size='large'
              type='primary'
              htmlType='submit'
              className='mt-3'
              loading={isLoadingClothingSize}
              disabled={
                !size
              }
            >
              Отправить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Layout>
  )
}
export default ClothingSize
