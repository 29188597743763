import React, { useReducer, useMemo, useEffect, useState } from 'react'
import {
  Typography,
  Select,
  Form,
  DatePicker,
  message,
  Col,
  Row,
  Button,
  Divider,
  Checkbox
} from 'antd'
import moment from 'moment-timezone'

import './Report.css'
import { simpleReducer } from '../../../helper'
import FilterTitle from './FilterTitle'
import { AppConfig } from '../../../AppConfig'
import { usePartnerStatusesQuery } from '../../../api/react-query/dict'
import { twoMinutes } from '../../../Constants'
import { useGetReportsQueue, useMutateDispatchReportToQueue } from '../../../api/react-query/reports'
import ReportList from '../../../components/Admin/Reports/ReportList'
import { useLocation } from 'react-router-dom'
import { ADMIN_PARTNERS_CONTENT_REPORT_PAGE, ADMIN_PARTNERS_RESEARCHER_REPORT_PAGE } from '../../../routes'

const { Title } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const now = moment()
const initialStart = moment().subtract(6, 'days')
const selectSearchFilter = (input, option) => {
  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export default function PartnersReport () {
  const location = useLocation()
  const [form] = Form.useForm()
  const initialState = {
    start: initialStart,
    end: now,
    statuses: [],
    includeHistory: false
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const [statuses, setStatuses] = useState([])

  const isContent = useMemo(() => {
    return location.pathname === ADMIN_PARTNERS_CONTENT_REPORT_PAGE
  }, [location])

  const isResearcher = useMemo(() => {
    return location.pathname === ADMIN_PARTNERS_RESEARCHER_REPORT_PAGE
  }, [location])

  const {
    data: partnerStatusesData,
    isError: isErrorPartnerStatuses,
    isSuccess: isSuccessPartnerStatuses
  } = usePartnerStatusesQuery({
    intent: true
  }, {
    staleTime: twoMinutes
  })

  useEffect(() => {
    if (isErrorPartnerStatuses) {
      message.error('Ошибка доступа к данным')
    }
  }, [isErrorPartnerStatuses])
  useEffect(() => {
    if (isSuccessPartnerStatuses) {
      setStatuses(partnerStatusesData?.result || [])
    }
  }, [isSuccessPartnerStatuses, partnerStatusesData])

  const {
    isError: isErrorReportsQueue,
    data: dataReportsQueue,
    isLoading: isLoadingReportsQueue,
    refetch: refetchReportsQueue
  } = useGetReportsQueue(isContent
    ? [AppConfig.report.types.partnersSzContent]
    : isResearcher
      ? [AppConfig.report.types.partnersResearcher]
      : [
        AppConfig.report.types.partners,
        AppConfig.report.types.partnersVerification
      ]
  )

  useEffect(() => {
    if (isErrorReportsQueue || dataReportsQueue?.errorMessage) {
      message.error(dataReportsQueue?.errorMessage || 'Не удалось получить отчеты')
    }
  }, [isErrorReportsQueue, dataReportsQueue])

  const {
    mutate: addPartnerReportToQueue,
    isError: isErrorAddPartnerReportToQueue,
    data: dataAddPartnerReportToQueue,
    isLoading: isLoadingAddPartnerReportToQueue
  } = useMutateDispatchReportToQueue()

  useEffect(() => {
    if (isErrorAddPartnerReportToQueue || dataAddPartnerReportToQueue?.errorMessage) {
      message.error(dataAddPartnerReportToQueue?.errorMessage || 'Не удалось поставить отчет в очередь')
    }
  }, [isErrorAddPartnerReportToQueue, dataAddPartnerReportToQueue])

  const datePickerDefaultValue = useMemo(() => [state.start, state.end], [])

  const handleFormChange = values => {
    if (Object.prototype.hasOwnProperty.call(values, 'includeHistory')) {
      const allNames = statuses.map(s => s.name)
      form.setFieldsValue({ statuses: values.includeHistory ? allNames : [] })
      setState({ ...values, statuses: values.includeHistory ? allNames : [] })
    } else setState(values)
  }

  const handleGetReport = (values) => {
    const data = {
      statuses: state.statuses,
      includeHistory: values.includeHistory,
      minRegistrationDate: moment(state.start)
        .set({ hour: 0, minute: 0, second: 0 })
        .format(AppConfig.formats.shortDateAndTimeApi),
      maxRegistrationDate: moment(state.end)
        .set({ hour: 24, minute: 0, second: 0 })
        .format(AppConfig.formats.shortDateAndTimeApi)
    }
    addPartnerReportToQueue({
      type: isContent
        ? AppConfig.report.types.partnersSzContent
        : isResearcher
          ? AppConfig.report.types.partnersResearcher
          : AppConfig.report.types.partners,
      data
    })
  }

  const handleAddReportToQueue = () => {
    const data = {
      minDate: moment(state.start)
        .set({ hour: 0, minute: 0, second: 0 })
        .format(AppConfig.formats.shortDateAndTimeApi),
      maxDate: moment(state.end)
        .set({ hour: 24, minute: 0, second: 0 })
        .format(AppConfig.formats.shortDateAndTimeApi)
    }
    addPartnerReportToQueue({
      type: AppConfig.report.types.partnersVerification,
      data
    })
  }

  const handleChangeFilter = ([start, end]) => setState({ start, end })

  const handleSelectAllStatuses = () => {
    const allNames = statuses.map(s => s.name)
    setState({ statuses: allNames })
    form.setFieldsValue({ statuses: allNames })
  }
  const handleResetStatuses = () => {
    setState({ statuses: [] })
    form.setFieldsValue({ statuses: [] })
  }

  return (
    <>
      <Title level={3} className='title-management'>Отчет по партнерам {isContent && 'контент'}{isResearcher && 'ресерч'}</Title>
      <Form
        form={form}
        onFinish={handleGetReport}
        onValuesChange={handleFormChange}
      >

        <FilterTitle title='Период'>
          <Form.Item>
            <RangePicker
              defaultValue={datePickerDefaultValue}
              onChange={handleChangeFilter}
            />
          </Form.Item>
        </FilterTitle>

        <FilterTitle title='Статус партнера'>
          <Form.Item name='statuses'>
            <Select
              disabled={state.includeHistory}
              size='middle'
              placeholder='Выберите статус'
              showSearch
              optionFilterProp='children'
              filterOption={selectSearchFilter}
              allowClear
              mode='multiple'
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider className='dropdown-divider' />
                  <Row justify='space-between' className='dropdown-footer'>
                    <Checkbox
                      value={state.statuses}
                      onChange={handleSelectAllStatuses}
                      checked={state.statuses?.length === statuses?.length}
                    >
                      Выбрать все
                    </Checkbox>
                    <a
                      onClick={handleResetStatuses}
                    >
                      Сбросить
                    </a>
                  </Row>
                </div>
              )}
            >
              {statuses?.map(status => (
                <Option key={status.id} value={status.name}>
                  {status.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </FilterTitle>

        <Form.Item
          name='includeHistory'
          valuePropName='checked'
          rules={[{
            transform: value => (value || undefined),
            type: 'boolean'
          }]}
        >
          <Checkbox
            value={state.includeHistory}
          >
            Включить историю статусов партнеров
          </Checkbox>
        </Form.Item>

        <Row>
          <Col className='mr-3'>
            <Button
              type='primary'
              htmlType='submit'
              loading={isLoadingAddPartnerReportToQueue}
            >
              Запросить отчет
            </Button>
          </Col>
          {!isContent && !isResearcher && (
            <Col className='mr-3'>
              <Button
                loading={isLoadingAddPartnerReportToQueue}
                type='primary'
                htmlType='button'
                onClick={handleAddReportToQueue}
              >
                Рекомендованы СБ
              </Button>
            </Col>
          )}
        </Row>
      </Form>
      <Divider />
      <Row className='mt-3' gutter={15} align='middle'>
        <Col>
          <Title level={5}>Отчеты</Title>
        </Col>
        <Col>
          <Button onClick={() => refetchReportsQueue()}>Обновить</Button>
        </Col>
      </Row>
      <ReportList
        isLoadingReports={isLoadingReportsQueue}
        dataReports={dataReportsQueue?.items}
      />
    </>
  )
}
