import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react'
// import * as Sentry from '@sentry/react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import ru from 'moment/locale/ru'
import momentTz from 'moment-timezone'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Store from './Store/Store'
import { queryClient } from './api/react-query/api'
// import { getSentryOptions } from './helper'

momentTz.updateLocale('ru', ru)
momentTz.locale('ru')
momentTz.tz.setDefault('Europe/Moscow')
// Sentry.init(getSentryOptions())

const store = new Store()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      {/* <Sentry.ErrorBoundary fallback='Извините, что-то пошло не так :('> */}
      <BrowserRouter>
        <Provider {...store}>
          <App />
        </Provider>
      </BrowserRouter>
      {/* </Sentry.ErrorBoundary> */}
    </React.StrictMode>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
