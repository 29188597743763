import { Form, Input } from 'antd'
import React, { useMemo } from 'react'
import { adminValidationErrors } from '../../../translates/errors'
import { profileScreen } from '../../../translates'
import SberIcon from '../SberIcon'
import TinkoffIcon from '../TinkoffIcon'

const InnFormItem = ({
  label = 'ИНН',
  rules = undefined,
  requiredRule = true,
  messageRule = adminValidationErrors.inn,
  size = 'large',
  placeholder = '401325754326',
  validator,
  classNameInput = '',
  showSberIcon = false,
  showTinkoffIcon = false,
  name = ['innDetails', profileScreen.input.inn.name]
}) => {
  const labelParam = useMemo(() => {
    return showSberIcon || showTinkoffIcon
      ? (
        <>
          {label}
          {showSberIcon && <SberIcon style={{ marginLeft: '0.5rem' }} />}
          {showTinkoffIcon && <TinkoffIcon style={{ marginLeft: '0.5rem' }} />}
        </>
      )
      : label
  }, [label, showSberIcon, showTinkoffIcon])

  return (
    <Form.Item
      name={name}
      label={labelParam}
      rules={rules ?? [{ required: requiredRule, message: messageRule, ...(validator ? { validator } : {}) }]}
    >
      <Input
        type='number'
        size={size}
        placeholder={placeholder}
        className={classNameInput}
      />
    </Form.Item>
  )
}
export default InnFormItem
