import React, { useContext, useEffect, useMemo, useReducer, useRef } from 'react'
import { message, Switch, Modal } from 'antd'
import { ConfigContext } from '../../../context/configContext'
import { simpleReducer } from '../../../helper'
import { useMutateUserStatus } from '../../../api/react-query/users'
import { ExclamationCircleFilled, CheckCircleFilled } from '@ant-design/icons'
import { COORDINATOR_PARTNER_ID } from '../../../Constants'

const portalLabels = {
  online: 'На портале',
  offline: 'Не на портале'
}

const { confirm } = Modal

function PortalButton () {
  const { isCoordinator, isRecruiter, isExternalCoordinator, user, setConfig, portalButtonState } = useContext(ConfigContext)
  const switchRef = useRef(null)
  const initialState = {
    portalNotice: null
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const isValidCoordinators = useMemo(() => {
    return isCoordinator || isRecruiter || isExternalCoordinator
  }, [isCoordinator, isExternalCoordinator, isRecruiter])

  const {
    mutateAsync: changeUserStatus,
    isLoading: isLoadingStatus,
    isError: isErrorStatus
  } = useMutateUserStatus()

  useEffect(() => {
    if (isErrorStatus) {
      message.error('Ошибка сохранения статуса')
    }
  }, [isErrorStatus])

  const handleClick = async (online) => {
    const result = await changeUserStatus({ data: { online }, isExternalCoordinator })
    if (result?.isSuccess) {
      setConfig({ user: { ...user, online } })
      if (online) {
        portalButtonState?.startStatusRequesting()
      } else {
        portalButtonState?.stopStatusRequesting()
        localStorage.removeItem(COORDINATOR_PARTNER_ID)
        setState({ portalNotice: null })
      }
    }
  }

  const showConfirm = (online) => {
    confirm({
      title: 'Подтвердите действие',
      icon: <ExclamationCircleFilled />,
      onOk () {
        handleClick(online)
      }
    })
  }

  const getPortalNotice = (statusData) => {
    if (statusData?.isUserOnline && statusData?.partnerId) {
      return <>Анкета получена <CheckCircleFilled style={{ color: 'green' }} /></>
    } else if (statusData?.isUserOnline && !statusData?.partnerId) {
      return 'Анкет для проверки нет'
    } else if (statusData || !statusData?.isSuccess) {
      return ''
    }
  }

  useEffect(() => {
    if (portalButtonState?.statusData?.isSuccess) {
      const portalNotice = getPortalNotice(portalButtonState?.statusData)
      if (portalNotice !== undefined) {
        setState({ portalNotice })
      }
      setConfig({ user: { ...user, online: portalButtonState?.statusData?.isUserOnline } })
    }
  }, [portalButtonState?.statusData])

  useEffect(() => {
    const handleKeydown = event => {
      switchRef.current?.blur()
      event.preventDefault()
    }
    const element = switchRef.current
    element?.addEventListener('keydown', handleKeydown)
    return () => {
      element?.removeEventListener('keydown', handleKeydown)
    }
  }, [])

  if (!isValidCoordinators) {
    return null
  }

  return (
    <>
      <Switch
        ref={switchRef}
        style={{ marginRight: 16, width: 115 }}
        onClick={user?.online ? showConfirm : handleClick}
        checkedChildren={portalLabels.online}
        unCheckedChildren={portalLabels.offline}
        checked={user?.online}
        loading={isLoadingStatus}
      />
      <div style={{ marginRight: 16, width: 160 }}>{state.portalNotice}</div>
    </>
  )
}

export default PortalButton
