import React, { useContext, useReducer } from 'react'
import './AdminPartnersStats.css'
import { ConfigContext } from '../../../context/configContext'
import { ADMIN_PARTNERS_PAGE } from '../../../routes'
import { simpleReducer } from '../../../helper'
import PartnersStatsList from './PartnersStatsList'
import { Row, Col } from 'antd'
import { AppConfig } from '../../../AppConfig'

const initialState = {
  isModalOpen: false,
  statType: null
}

function AdminPartnersStats () {
  const { isAdmin, isSecurity, isRecruiter, partnersStats } = useContext(ConfigContext)
  const [state, setState] = useReducer(simpleReducer, initialState)

  const handleClose = () => setState({ isModalOpen: false })
  const handleOpen = (statType) => setState({ isModalOpen: true, statType })

  const handleClickRecruitersOnline = () => handleOpen(AppConfig.partnerStatTypes.recruitersOnline)
  // const handleClickCardForm = () => handleOpen(AppConfig.partnerStatTypes.bankRequisiteForms)
  // const handleClickEmploymentType = () => handleOpen(AppConfig.partnerStatTypes.employmentTypeForms)
  const handleClickVerifiedPartners = () => handleOpen(AppConfig.partnerStatTypes.verifiedPartners)
  const handleClickWaitingPartners = () => handleOpen(AppConfig.partnerStatTypes.waitingPartners)

  if (!partnersStats || (!isAdmin && !isSecurity && !isRecruiter) || location.pathname !== ADMIN_PARTNERS_PAGE) {
    return null
  }

  return (
    <>
      <div className='partners-stats'>
        {
          (isAdmin || isRecruiter) && (
            <Row>
              <Col>
                <a onClick={handleClickWaitingPartners}>Анкет на проверке</a>: {partnersStats?.waitingPartners}
              </Col>
            </Row>
          )
        }

        {
          (isAdmin || isSecurity) && (
            <Row>
              <Col>
                <a onClick={handleClickVerifiedPartners}>Проверка СБ</a>: {partnersStats?.verifiedPartners}
              </Col>
            </Row>
          )
        }

        {
          (isAdmin || isRecruiter) && (
            <Row>
              <Col>
                {
                  isRecruiter
                    ? (<>Координаторы на портале: {partnersStats?.usersOnline}</>)
                    : (<><a onClick={handleClickRecruitersOnline}>Координаторы на портале</a>: {partnersStats?.usersOnline}</>)
                }
              </Col>
            </Row>
          )
        }
        {/* <Row> */}
        {/*  <Col span={14}>Анкет ожидающих проверки</Col> */}
        {/*  <Col span={10}>Загружают документы: {partnersStats?.activePartners}</Col> */}
        {/* </Row> */}
        {/* <Row> */}
        {/*  <Col span={7}> */}
        {/*    <a onClick={handleClickNewPartners}>Новые</a>: {partnersStats?.waitingPartners} */}
        {/*  </Col> */}
        {/*  <Col span={7}> */}
        {/*    <a onClick={handleClickEditInfo}>Корректирующие</a>: {partnersStats?.waitingProfileForms} */}
        {/*  </Col> */}
        {/*  <Col span={10}>Анкет в работе: {partnersStats?.takenPartners}</Col> */}
        {/* </Row> */}
        {/* <Row> */}
        {/*  <Col span={7}> */}
        {/*    <a onClick={handleClickCardForm}>Банковские</a>: {partnersStats?.waitingBankRequisiteForms} */}
        {/*  </Col> */}
        {/*  <Col span={7}> */}
        {/*    <a onClick={handleClickEmploymentType}>Смена занятости</a>: {partnersStats?.waitingEmploymentTypeForms} */}
        {/*  </Col> */}
        {/*  <Col span={10}> */}
        {/*    { */}
        {/*      isAdmin */}
        {/*        ? (<a onClick={handleClickCoordinatorsOnline}>Координаторов на портале</a>) */}
        {/*        : (<>Координаторов на портале</>) */}
        {/*    }: {partnersStats?.coordinatorsOnline} */}
        {/*  </Col> */}
        {/* </Row> */}
      </div>
      {
        (isAdmin || isSecurity || isRecruiter) && state.isModalOpen &&
          <PartnersStatsList isModalOpen={state.isModalOpen} onClose={handleClose} statType={state.statType} />
      }
    </>
  )
}

export default AdminPartnersStats
