import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Checkbox, Form, Layout, message, Spin } from 'antd'
import { observer } from 'mobx-react'

import { apiErrorCodes, partnerStatusCodes, AppConfig, innDetailsStatusCodes, selfEmployed } from '../../../AppConfig'
import { useStores } from '../../../Store/Store'
import { useDataApi } from '../../../api/useDataApi'
import { createApiDataFromForm, getFileId, validateAllPhotosUploading } from '../../../helper'
import './ProfileStyle.css'
import { errors, offerScreen, profileScreen } from '../../../translates'
import ErrorBlock from './ErrorBlock'
import BankDetails from './BankDetails'
import Documents from './Documents'
import { getHeaders } from '../../../api/react-query/api'
import { formLayout, partnerRoles4MultiOffer, policyTypes } from '../../../Constants'
import { Link } from 'react-router-dom'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import { PlaceOfBirthFormItem, RegistrationAddressBlock } from '../../Common'
import PersonalDataSelfEmployed from './PersonalDataSelfEmployed'
import { useRequiredOffers } from '../../../hooks'
import InnPhotoWithDescription from './InnPhotoWithDescription'

const { Content } = Layout

const ProfileSelfEmployed = ({ onSuccess }) => {
  const [form] = Form.useForm()
  const {
    partnerProfile
  } = useStores()
  const [role, setRole] = useState(false)
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [trigger, setTrigger] = useState(false)
  const [existsNfc, setExistsNfc] = useState(partnerProfile?.profile?.personalData?.hasNfc)

  useEffect(() => {
    if (partnerProfile.form?.personalData?.mobilePhone) {
      form.setFieldsValue(partnerProfile.form)
      setTrigger(true)
      const currentInnPhoto = getFileId(partnerProfile.form, profileScreen.input.innPhoto.name)
      if (currentInnPhoto &&
        partnerProfile.form?.innDetails?.status === innDetailsStatusCodes.declined &&
        partnerProfile.form?.personalData?.legalForm === selfEmployed
      ) {
        form.resetFields([profileScreen.input.innPhoto.name])
      }
    }
  }, [form, partnerProfile.form])

  const myRef = useRef(null)
  useEffect(() => {
    if (myRef.current) {
      document.body.scrollIntoView({ behavior: 'smooth' })
    }
  }, [myRef])
  // const { data, isError: isErrorOffer } = useGetOffer(role, {
  //   enabled: !!role
  // })
  // const { mutate: accept, data: acceptData, isLoading: isLoadingAccept } = useMutateAcceptOffer()

  // const offerData = useMemo(() => data || {}, [data])

  // useEffect(() => {
  //   if (acceptData?.isSuccess) {
  //     onSuccess(AppConfig.partnerSteps.stepSuccess)
  //   } else if (acceptData?.errorMessage) {
  //     onSuccess(AppConfig.partnerSteps.stepOffer)
  //     message.error(acceptData.errorMessage)
  //   }
  // }, [acceptData])
  /**
   * Errors from api requests
   */
  // useEffect(() => {
  //   if (isErrorOffer) {
  //     message.error(offerScreen.errors.offer)
  //   }
  // }, [isErrorOffer])

  const { acceptListOffers, isLoadingAccept } = useRequiredOffers({ role, onSuccess })

  /**
   * Profile update
   */
  const [{ data: profileUpdated, isError: errorProfileUpdated, isLoading }, doFetchProfileUpdate] = useDataApi({})

  useEffect(() => {
    // async function acceptOffer (offerData) {
    //   const hash = await digestMessage(offerData.offer?.subject + offerData.offer?.body + offerData.offer?.agreementTerm)
    //   accept({
    //     offerId: offerData.offer?.id,
    //     offerHash: hash,
    //     validityFrom: offerData.validityFrom,
    //     validityTo: offerData.validityTo
    //   })
    // }
    if (profileUpdated?.isSuccess && profileUpdated.profile?.statusCode === partnerStatusCodes.draft) {
      message.success(profileScreen.messages.draftSaved)
      partnerProfile.setPartnerProfile({
        ...profileUpdated,
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
    }
    if (profileUpdated?.isSuccess && profileUpdated.profile?.statusCode === partnerStatusCodes.profileFilled) {
      message.success(profileScreen.messages.profileSaved)
      acceptListOffers()
      // acceptOffer(offerData)
    }
    if (profileUpdated?.isSuccess && profileUpdated.profile?.statusCode === partnerStatusCodes.verification) {
      message.success(profileScreen.messages.profileSaved)
      onSuccess(AppConfig.partnerSteps.stepSuccess)
    }

    if (profileUpdated?.errorCode === apiErrorCodes.innDuplicate) {
      setBackendFieldsErrors({ inn: [profileUpdated?.errorMessage] })
    }
  }, [
    profileUpdated,
    errorProfileUpdated,
    onSuccess,
    partnerProfile
    // offerData,
    // accept
  ])

  useEffect(() => {
    if (Object.keys(backendFieldsErrors).length > 0) {
      form.setFields(Object.keys(backendFieldsErrors).map(key => ({
        name: key,
        errors: backendFieldsErrors[key]
      })))
    }
  }, [backendFieldsErrors])

  const [
    {
      data: profileChecked,
      isError: isErrorProfileChecked,
      isLoading: isLoadingProfileChecked
    },
    doFetchProfileCheck
  ] = useDataApi({})

  useEffect(() => {
    if (profileChecked?.isSuccess) {
      partnerProfile.setPartnerProfile({
        ...profileChecked,
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
    }
  }, [profileChecked, isErrorProfileChecked, partnerProfile])

  const onFinish = (values) => {
    if (!values.accept) return

    setBackendFieldsErrors({})
    if (!validateAllPhotosUploading(values)) {
      message.error(errors.documentsUploading)
      return
    }

    delete values?.personalData?.hasNfc
    if (values?.personalData && partnerProfile?.profile?.showNfc && partnerProfile?.visible?.personalData) {
      values.personalData.hasNfc = existsNfc
    }

    const info = createApiDataFromForm(values)
    // Если коррекция - отправляем только поля, которые необходимо обновить (со statusReason)
    if (partnerProfile?.isCorrectionStatus) {
      Object.keys(partnerProfile?.profile).forEach(key => {
        if (!partnerProfile?.profile?.[key]?.allowEdit) {
          delete info[key]
        }
      })
    }

    doFetchProfileUpdate('partner/profile/', {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify({
        info
      })
    })
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo, errorInfo.values.personalData)
  }

  const onFieldsChange = fields => {
    if (partnerProfile?.profile?.statusCode !== partnerStatusCodes.free) {
      return false
    }
    if (['bankAccount', 'bankBik', 'bankName'].includes(fields[0]?.name?.[0])) {
      partnerProfile.setPartnerProfile({
        profile: {
          ...partnerProfile.profile,
          bankDetails: {
            ...partnerProfile.profile.bankDetails,
            [fields[0].name[0]]: fields[0].value,
            bankDetailsPhoto: null
          }
        },
        ...(partnerProfile?.id ? { id: partnerProfile?.id } : {})
      })
    }
  }

  const onContinueLater = () => {
    const values = form.getFieldsValue()

    delete values?.personalData?.hasNfc
    if (values?.personalData && partnerProfile?.profile?.showNfc && partnerProfile?.visible?.personalData) {
      values.personalData.hasNfc = existsNfc
    }

    const info = createApiDataFromForm(values)

    doFetchProfileUpdate('partner/profile/draft', {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify({
        info
      })
    })
  }

  const handleUpdateImage = (item, info) => { partnerProfile.updateForm({ ...form.getFieldsValue(), [item.name]: info.fileList }); setTrigger(true) }
  const handleDeleteImage = (item) => { partnerProfile.updateForm({ ...form.getFieldsValue(), [item.name]: [] }); setTrigger(true) }

  const documentList = useMemo(() => AppConfig.documentNameList
    .filter(
      d => {
        return d !== profileScreen.input.lmkMainPhoto.name ||
          (d === profileScreen.input.lmkMainPhoto.name &&
            partnerProfile?.profile?.lmkDetails?.hasMedicalBook &&
            partnerProfile?.required?.lmkMainPhoto &&
            partnerProfile?.visible?.personalData)
      }
    )
    .filter(
      d => {
        return d !== profileScreen.input.lmkAllowancePhoto.name ||
          (d === profileScreen.input.lmkAllowancePhoto.name &&
            partnerProfile?.profile?.lmkDetails?.hasMedicalBook &&
            partnerProfile?.required?.lmkAllowancePhoto &&
            partnerProfile?.visible?.personalData)
      }
    )
    .filter(
      d => {
        return d !== profileScreen.input.lmkAttestationPhoto.name ||
          (d === profileScreen.input.lmkAttestationPhoto.name &&
            partnerProfile?.profile?.lmkDetails?.hasMedicalBook &&
            partnerProfile?.required?.lmkAttestationPhoto &&
            partnerProfile?.visible?.personalData)
      }
    )
    .filter(
      d => {
        return d !== profileScreen.input.passportPhoto3.name ||
        (d === profileScreen.input.passportPhoto3.name && partnerProfile?.profile?.showNfc && existsNfc && partnerProfile?.required?.passportPhoto3)
      }
    )
    .filter(
      d => d !== profileScreen.input.innPhoto.name
    ), [
    AppConfig.documentNameList,
    existsNfc,
    partnerProfile?.required,
    partnerProfile?.profile?.showNfc,
    partnerProfile?.profile?.lmkDetails?.hasMedicalBook
  ])

  useEffect(() => {
    setExistsNfc(Boolean(partnerProfile?.profile?.personalData?.hasNfc))
    const country = form.getFieldValue(['personalData', 'registrationAddress', 'country'])
    const citizenship = form.getFieldValue(['personalData', 'citizenship'])
    form.setFieldsValue({
      personalData: {
        registrationAddress: { country: country ?? profileScreen.input.registrationAddresscountry.placeholder },
        citizenship: citizenship ?? AppConfig.countries.Russia
      }
    })
  }, [partnerProfile?.profile?.personalData?.hasNfc])

  return (
    <Content className='Profile' ref={myRef}>
      <Form
        {...formLayout}
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={onFieldsChange}
        form={form}
        layout='vertical'
        scrollToFirstError
      >
        <ErrorBlock />
        <PersonalDataSelfEmployed
          form={form}
          doFetchProfileCheck={doFetchProfileCheck}
          isLoading={isLoadingProfileChecked}
          setRole={setRole}
          trigger={trigger}
          hasNfc={partnerProfile?.profile?.showNfc && partnerProfile?.visible?.personalData ? existsNfc : null}
        />

        {
          !isLoadingAccept && partnerProfile?.profile?.innDetails?.status !== innDetailsStatusCodes.accepted && (
            <InnPhotoWithDescription
              profile={partnerProfile?.profile}
              form={form}
            />
          )
        }

        <BankDetails form={form} />
        <RegistrationAddressBlock
          required={{
            zipCode: true,
            country: true,
            region: true,
            street: true,
            house: true
          }}
          disabled={{ country: true }}
        />
        <PlaceOfBirthFormItem requiredRule />

        <Documents
          documents={documentList}
          trigger={trigger}
          form={form}
          required={partnerProfile?.required}
          filterDocuments={useCallback((d) => partnerProfile?.visible?.[d], [partnerProfile?.visible])}
          onUpdate={handleUpdateImage}
          onDelete={handleDeleteImage}
          onPhotoDoc={() => {
            partnerProfile.updateForm({ ...form.getFieldsValue() })
          }}
          disabledUploadModal
        />

        <Spin spinning={isLoading || isLoadingAccept} size='large'>
          {partnerProfile?.profile?.statusCode !== partnerStatusCodes.correction &&
          partnerProfile?.profile?.statusCode !== partnerStatusCodes.free &&
            <Form.Item>
              <Button
                ghost
                block
                size='large'
                type='primary'
                onClick={onContinueLater}
                className='margin-top-30'
              >
                {profileScreen.button.continueLater}
              </Button>
            </Form.Item>}
          <Form.Item
            name='accept'
            valuePropName='checked'
            rules={[{
              required: true,
              transform: value => (value || undefined),
              type: 'boolean',
              message: offerScreen.offerText
            }]}
            className='offer'
          >
            <Checkbox disabled={!role}>
              {offerScreen.input.accept.placeholder}:{' '}
              <Link
                disabled={!role}
                to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offer}&role=${role}`}
                onClick={() => {
                  partnerProfile.updateForm({ ...form.getFieldsValue() })
                }}
              >
                {offerScreen.offerParts.roleOffer}
              </Link>
              {
                partnerRoles4MultiOffer.includes(role) && (
                  <>
                    ,{' '}
                    <Link
                      disabled={!role}
                      to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.cyberSecurityPolicy}`}
                      onClick={() => {
                        partnerProfile.updateForm({ ...form.getFieldsValue() })
                      }}
                    >
                      {offerScreen.offerParts.cyberSecurityPolicy}
                    </Link>,{' '}
                    <Link
                      disabled={!role}
                      to={`${PROFILE_MAIN_PAGE}?action=${AppConfig.formScreenActions.offerType}&offerPolicy=${policyTypes.assemblyAndDeliveryPolicy}`}
                      onClick={() => {
                        partnerProfile.updateForm({ ...form.getFieldsValue() })
                      }}
                    >
                      {offerScreen.offerParts.assemblyAndDeliveryPolicy}
                    </Link>.
                  </>
                )
              }
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              block
              size='large'
              type='primary'
              htmlType='submit'
              className='continue-button'
            >
              {profileScreen.button.send}
            </Button>
          </Form.Item>
        </Spin>
      </Form>
    </Content>
  )
}
export default observer(ProfileSelfEmployed)
