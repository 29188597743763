export const authScreen = {
  title: 'Партнерский портал',
  titleModal: 'Номер телефона',
  input: {
    phoneNumber: {
      placeholder: '+7xxxxxxxxxx',
      label: 'Введите свой номер телефона',
      message: 'Пожалуйста, укажите телефон в формате +70001234567'
    },
    smsCode: {
      placeholder: '',
      label: 'Введите смс код',
      message: 'Пожалуйста, укажите смс код'
    }
  },
  button: {
    submit1: 'Получить код',
    retryAfter: 'Получить код повторно можно через ',
    submit2: 'Войти по номеру телефона',
    sberId: 'Войти по Сбер ID'
  },
  personalDataPolicy: 'Согласие на обработку персональных данных',
  electronicSignaturePolicy: 'Соглашение об использовании простой электронной подписи',
  partnersSberMarketPolicy: 'Партнеры СберМаркета',
  personalPolicy: 'Обработка персональных данных'
}
