import { errors } from '../translates'
import { usePartnerClothingSizesQuery } from '../api/react-query/dict'
import useCommonDict from './useCommonDict'

export default function useClothingSizes (token = null) {
  return useCommonDict({
    errorMessage: errors.clothingSize,
    queryMethod: usePartnerClothingSizesQuery,
    queryBody: {
      ...(token && { token })
    }
  })
}
