import React, { useState, useEffect, useMemo } from 'react'
import { Layout, Button, Typography, PageHeader, Spin, message, Row } from 'antd'
import { observer } from 'mobx-react'

import './PolicyAgreementStyle.css'
import { LeftOutlined } from '@ant-design/icons'
import MobileHeader from '../MobileHeader/MobileHeader'
import { useHistory, useLocation } from 'react-router-dom'
import {
  useGetPersonalDataPolicyQuery,
  useGetElectronicSignaturePolicyQuery,
  useGetPartnersSberMarketQuery,
  useGetCyberSecurityPolicyQuery,
  useGetAssemblyDeliveryPolicy, useGetMedicalAcceptOffer
} from '../../../api/react-query/partners'
import { policyTypes } from '../../../Constants'
import { agreementScreen } from '../../../translates'
import { useStores } from '../../../Store/Store'
import { isDisabledMedicalAcceptOffer, renderMedicalOfferTemplate } from '../../../helper'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import { AppConfig } from '../../../AppConfig'

const { Paragraph } = Typography

export const fromEditTypes = {
  editLmk: 'editLmk',
  editLmkWithoutPassport: 'editLmkWithoutPassport',
  editInfo: 'editInfo'
}

const PolicyAgreement = ({ profileLmkState, profileEditInfoState }) => {
  const { partnerProfile } = useStores()
  const [agreement, setAgreement] = useState({})
  const history = useHistory()
  const { pathname, search } = useLocation()
  const offerPolicy = useMemo(() => {
    const params = new URLSearchParams(search)
    return params.get('offerPolicy')
  }, [search])
  const fromEdit = useMemo(() => {
    const params = new URLSearchParams(search)
    return params.get('fromEdit')
  }, [search])

  const role = useMemo(() => {
    const params = new URLSearchParams(search)
    const role = params.get('role')
    return [AppConfig.partnerRoles.researcher, AppConfig.partnerRoles.szContent].includes(role) ? role : undefined
  }, [search])

  const currentProfileEdit = useMemo(() => {
    if (fromEdit === fromEditTypes.editLmk) {
      return {
        personalData: {
          ...partnerProfile?.form?.personalData,
          ...profileLmkState?.form?.personalData
        }
      }
    } else if (fromEdit === fromEditTypes.editInfo) {
      return {
        personalData: {
          ...partnerProfile?.form?.personalData,
          ...profileEditInfoState?.form?.personalData
        }
      }
    }
    return partnerProfile?.form
  }, [partnerProfile?.form, fromEdit])

  const {
    data: dataPersonalDataPolicy,
    isError: isErrorPersonalDataPolicy,
    isSuccess: isSuccessPersonalDataPolicy,
    isLoading: isLoadingPersonalDataPolicy
  } = useGetPersonalDataPolicyQuery(role, {
    enabled: pathname.slice(1) === policyTypes.personalDataPolicy
  })
  const {
    data: dataElectronicSignaturePolicy,
    isError: isErrorElectronicSignaturePolicy,
    isSuccess: isSuccessElectronicSignaturePolicy,
    isLoading: isLoadingElectronicSignaturePolicy
  } = useGetElectronicSignaturePolicyQuery({
    enabled: pathname.slice(1) === policyTypes.electronicSignaturePolicy
  })
  const {
    data: dataPartnersSberMarket,
    isError: isErrorPartnersSberMarket,
    isSuccess: isSuccessPartnersSberMarket,
    isLoading: isLoadingPartnersSberMarket
  } = useGetPartnersSberMarketQuery({
    enabled: pathname.slice(1) === policyTypes.partnersSberMarketPolicy
  })

  const {
    data: dataCyberSecurityPolicy,
    isError: isErrorCyberSecurityPolicy,
    isSuccess: isSuccessCyberSecurityPolicy,
    isLoading: isLoadingCyberSecurityPolicy
  } = useGetCyberSecurityPolicyQuery({
    enabled: offerPolicy === policyTypes.cyberSecurityPolicy
  })

  const {
    data: dataAssemblyDeliveryPolicy,
    isError: isErrorAssemblyDeliveryPolicy,
    isSuccess: isSuccessAssemblyDeliveryPolicy,
    isLoading: isLoadingAssemblyDeliveryPolicy
  } = useGetAssemblyDeliveryPolicy({
    enabled: offerPolicy === policyTypes.assemblyAndDeliveryPolicy
  })

  const {
    data: dataMedicalAcceptOffer,
    isError: isErrorMedicalAcceptOffer,
    isSuccess: isSuccessMedicalAcceptOffer,
    isLoading: isLoadingMedicalAcceptOffer
  } = useGetMedicalAcceptOffer({
    enabled: offerPolicy === policyTypes.lmkAgreement,
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: false
  })

  useEffect(() => {
    if (isErrorPersonalDataPolicy ||
      isErrorElectronicSignaturePolicy ||
      isErrorPartnersSberMarket ||
      isErrorCyberSecurityPolicy ||
      isErrorAssemblyDeliveryPolicy ||
      isErrorMedicalAcceptOffer
    ) {
      message.error(agreementScreen.errors.agreement)
    }
    if (isSuccessPersonalDataPolicy &&
      pathname.slice(1) === policyTypes.personalDataPolicy
    ) {
      setAgreement(dataPersonalDataPolicy.agreement)
    }
    if (isSuccessElectronicSignaturePolicy &&
      pathname.slice(1) === policyTypes.electronicSignaturePolicy
    ) {
      setAgreement(dataElectronicSignaturePolicy.agreement)
    }
    if (isSuccessPartnersSberMarket &&
      pathname.slice(1) === policyTypes.partnersSberMarketPolicy
    ) {
      setAgreement(dataPartnersSberMarket.agreement)
    }
    if (isSuccessCyberSecurityPolicy && offerPolicy === policyTypes.cyberSecurityPolicy) {
      setAgreement(dataCyberSecurityPolicy.agreement)
    }
    if (isSuccessAssemblyDeliveryPolicy && offerPolicy === policyTypes.assemblyAndDeliveryPolicy) {
      setAgreement(dataAssemblyDeliveryPolicy.agreement)
    }
    if (isSuccessMedicalAcceptOffer && offerPolicy === policyTypes.lmkAgreement) {
      if (isDisabledMedicalAcceptOffer(currentProfileEdit)) {
        history.replace(PROFILE_MAIN_PAGE)
      } else {
        setAgreement({
          ...dataMedicalAcceptOffer.template,
          body: renderMedicalOfferTemplate(dataMedicalAcceptOffer.template.content, currentProfileEdit)
        })
      }
    }
  }, [
    dataElectronicSignaturePolicy,
    isErrorElectronicSignaturePolicy,
    isSuccessElectronicSignaturePolicy,
    dataPersonalDataPolicy,
    isErrorPersonalDataPolicy,
    isSuccessPersonalDataPolicy,
    dataPartnersSberMarket,
    isErrorPartnersSberMarket,
    isSuccessPartnersSberMarket,
    dataCyberSecurityPolicy,
    isSuccessCyberSecurityPolicy,
    isErrorCyberSecurityPolicy,
    dataAssemblyDeliveryPolicy,
    isErrorAssemblyDeliveryPolicy,
    isSuccessAssemblyDeliveryPolicy,
    isErrorMedicalAcceptOffer,
    isSuccessMedicalAcceptOffer,
    dataMedicalAcceptOffer,
    currentProfileEdit
  ])

  useEffect(() => {
    if (agreement?.body) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [agreement?.body])

  return (
    <Layout className='PolicyAgreement'>
      <MobileHeader />
      <Row className='mt-3'>
        <Button type='text' onClick={() => { history.goBack() }}>
          <LeftOutlined /> {agreementScreen.button.back}
        </Button>
      </Row>
      <Spin
        spinning={isLoadingPersonalDataPolicy ||
          isLoadingElectronicSignaturePolicy ||
          isLoadingPartnersSberMarket ||
          isLoadingCyberSecurityPolicy ||
          isLoadingAssemblyDeliveryPolicy ||
        isLoadingMedicalAcceptOffer}
        size='large'
      >
        <Row justify='space-around'>
          <PageHeader title={
            agreementScreen.title[pathname.slice(1)] || agreementScreen.title[offerPolicy] || ''
          }
          />
        </Row>
        {
          agreement?.body &&
            <Row>
              <Paragraph className='document document-m0'>
                <div dangerouslySetInnerHTML={{ __html: agreement.body }} />
              </Paragraph>
            </Row>
        }
      </Spin>
    </Layout>
  )
}
export default observer(PolicyAgreement)
